<template>
    <b-modal
        :id="'modalFormForgottenPassword' + componentKey"
        :cancel-title="$t('form.cancel')"
        :ok-title="$t('auth.send')"
        :title="$tc('auth.forgotten_password', 2)"
        footerBgVariant="light"
        headerBgVariant="light"
        scrollable
        size="md"
        @cancel=""
        @hide="modalClose"
        @ok="send"
    >
        <!-- message -->
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <div class="text-secondary" v-html="$t('auth.forgotten_password_message')"></div>
            <hr>
            <!-- form -->
            <b-form ref="form">
                <!-- login email or username -->
                <b-form-group
                    :label="$t('auth.email_username')"
                    label-for="email"
                >
                    <b-form-input
                        id="email"
                        v-model="record.emailUsername"
                        :placeholder="$t('auth.email_username_placeholder')"
                        required
                        :state="validEmailUsername"
                        size="sm"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="validEmailUsername">
                        <div v-html="$t('form.required_field')"/>
                    </b-form-invalid-feedback>
                </b-form-group>
            </b-form>
        </b-overlay>
        <!-- alert warning -->
        <b-alert
            :show="errors.message != null"
            class="mb-0"
            dismissible
            variant="danger"
            @dismissed="resetErrors"
        >
            <div v-html="formatError"></div>
        </b-alert>
    </b-modal>
</template>

<script>
export default {
    name: "ModalFormForgottenPassword",
    components: {},
    props: {
        componentKey: {
            type: String,
            default: ''
        },
        uri: null,
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            form: {
                valid: true,
            },
            record: {
                emailUsername: null,
            },
            errors: {
                message: null,
            },
        }
    },
    mounted() {
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        resetErrors() {
            this.errors.message = null;
        },
        // <-- modal -->
        modalClose() {
            // this.showOverlay(false);
        },
        hideModal() {
            // <-- hide the modal manually -->
            this.$nextTick(() => {
                this.$bvModal.hide('modalFormForgottenPassword' + this.componentKey)
            });
        },
        // <-- form -->
        validate() {
            this.form.valid = this.$refs.form.checkValidity();
        },
        send(bvModalEvent) {
            this.errors.message = null;
            bvModalEvent.preventDefault();
            this.validate();
            if (!this.form.valid) {
                return;
            }
            // <-- axios -->
            this.showOverlay(true);

            let href = location.protocol + '//' + location.host + '/#/';
            // <-- axios -->
            this.axios.post(this.uri.main + '/forgotten_password',
                {
                    'locale': this.$i18n.locale,
                    'href': href,
                    'email': this.record.emailUsername.toLowerCase(),

                })
                .then((response) => {
                    this.hideModal();
                    this.showOverlay(false);

                })
                .catch((error) => {
                    this.showOverlay(false);
                    // if(error.response === undefined){
                    //     return;
                    // }
                    error = error.response.data;
                    this.errors.message = error;
                })
                .finally(() => {
                });
        },
    },
    computed: {
        // <-- errors -->
        formatError() {
            let message = '';
            let error = this.errors.message;
            if (error === null) {
                return null;
            }
            // <-- error.message -->
            if (error.message !== undefined) {
                message = error.message;
            }
            // <-- error.errors -->
            if (error.errors !== undefined && typeof error.errors === 'object') {
                for (let key in error.errors) {
                    message += '<br><b>' + key + ':</b> ' + error.errors[key][0];
                }
            }
            // <--  -->
            return message;
        },
        // <-- validate -->
        validEmailUsername() {
            if (this.form.valid) {
                return null;
            }
            let value = this.record.emailUsername;
            return value == null || value.length === 0 ? false : null;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
