<template>
    <div  class="height-to-window">
        <landing-page></landing-page>
    </div>
</template>

<script>

    import LandingPage from "@/components/home/LandingPage";
    export default {
        name: 'home',
        components: {LandingPage}
    };
</script>

<style scoped>

</style>
