import Vue from 'vue'

// @group MIXIN
Vue.mixin({
    data() {
        return {}
    },
    methods: {
        /**
         * @vuese
         * Toolbar types for Vue2-Editor
         */
        __editorToolbar(type) {
            switch (type) {
                case 'minimal':
                    return [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['clean']
                    ];
                    break;
                case 'basic':
                    return [
                        // [{'font': []}],
                        // [{'header': [false, 1, 2, 3, 4, 5, 6,]}],
                        // [{'size': ['small', false, 'large', 'huge']}],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                        [{'script': 'sub'}, {'script': 'super'}],
                        // [{'header': 1}, {'header': 2}],
                        // ['blockquote', 'code-block'],
                        [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
                        // [{'indent': '-1'}, {'indent': '+1'}],
                        // [{'color': []}, {'background': []}],
                        // ['link', 'image', 'video', 'formula'],
                        // [{'direction': 'rtl'}],
                        ['clean']
                    ];
                    break;
                case 'extended':
                    return [
                        [{'font': []}],
                        [{'size': ['small', false, 'large', 'huge']}],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                        [{'script': 'sub'}, {'script': 'super'}],
                        [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
                        // [{'indent': '-1'}, {'indent': '+1'}],
                        ['link', 'image', 'video'],
                        [{'color': []}, {'background': []}],
                        ['clean']
                    ];
                    break;

                case 'full':
                    return [
                        [{'font': []}],
                        [{'header': [false, 1, 2, 3, 4, 5, 6,]}],
                        [{'size': ['small', false, 'large', 'huge']}],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                        [{'header': 1}, {'header': 2}],
                        ['blockquote', 'code-block'],
                        [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
                        [{'script': 'sub'}, {'script': 'super'}],
                        [{'indent': '-1'}, {'indent': '+1'}],
                        [{'color': []}, {'background': []}],
                        ['link', 'image', 'video', 'formula'],
                        [{'direction': 'rtl'}],
                        ['clean']
                    ];
                    break;
                default:
                    // <-- normal toolbar -->
                    return [
                        [{'size': ['small', false, 'large', 'huge']}],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                        [{'script': 'sub'}, {'script': 'super'}],
                        [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
                        [{'color': []}, {'background': []}],
                        [{'indent': '-1'}, {'indent': '+1'}],
                        ['clean']
                    ]
            }
            return [];
        },
    },
    computed: {
    },
});
