import Vue from 'vue'

Vue.mixin({
    data() {
        return {}
    },
    methods: {
        __gotoDestinations(routeStamp) {
            // <-- goto -->
            this.$router.push(`/let-s-go`
                + '/' + routeStamp
            ).then();
        },
        __gotoExperiences(destinationId) {
            // <-- goto -->
            this.$router.push(`/let-s-go`
                + '/' + this.$route.params.route
                + '/' + destinationId
            ).then();
        },
        __gotoExperience(experience, endpoints) {
            let endpoint = endpoints.find((n) => {
                if(!this.__isNull(experience[n])){
                    return n;
                }
            });
            // <-- goto -->
            this.$router.push(`/let-s-go`
                + '/' + this.$route.params.route
                + '/' + this.$route.params.destination
                + '/' + experience.id
                + '/' + endpoint
            ).then();
        },
    },
    computed: {},
});
