<template>
    <b-modal
        :id="'modalFormRegistration' + componentKey"
        :cancel-title="$t('form.cancel')"
        :ok-title="$t('auth.register')"
        :title="$t('auth.register')"
        footerBgVariant="light"
        headerBgVariant="light"
        scrollable
        size="md"
        @cancel=""
        @hide="modalClose"
        @ok="save"
        @show="modalOpen"
    >
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <!-- form -->
            <b-form ref="form">
                <!-- email -->
                <b-form-group
                    id="group-email"
                    :label="$t('auth.email')"
                    label-for="email"
                >
                    <b-form-input
                        id="email"
                        v-model="record.email"
                        :placeholder="$t('auth.email_placeholder')"
                        :state="validEmail"
                        required
                        type="email"
                    ></b-form-input>
                    <b-form-invalid-feedback :state="validEmail">
                        <div v-html="$t('auth.email_warning')"/>
                    </b-form-invalid-feedback>
                </b-form-group>
                <!-- password -->
                <b-form-group
                    id="group-password"
                    :label="$t('auth.password')"
                    label-for="password"
                >
                    <b-form-input
                        id="password"
                        v-model="record.password"
                        :placeholder="$tc('auth.password_warning', 1)"
                        :state="validPassword"
                        :type="record.showPassword === true ? 'text' : 'password'"
                        required
                    ></b-form-input>
                    <b-form-invalid-feedback :state="validPassword">
                        <div v-html="$tc('auth.password_warning', 1)"/>
                    </b-form-invalid-feedback>
                </b-form-group>
                <!-- show password -->
                <b-form-checkbox
                    id="show-password"
                    v-model="record.showPassword"
                    name="active"
                >
                    {{$t('auth.show_password')}}
                </b-form-checkbox>
                <!-- horizontal line -->
                <hr class="my-4">
                <!-- agreement -->
                <b-form-group
                    id="group-agreement"
                    class="mt-3"
                    label=""
                    label-for="agreement"
                >
                    <b-form-checkbox
                        id="agreement"
                        v-model="record.agreement"
                    >
                        <!--                        <span v-html="$t('auth.agreement', {uri_conditions: 'https://www.kanglica.si/public/general/index_general.php?mode=splo%C5%A1ni_pogoji'-->
                        <span v-html="$t('auth.agreement', {uri_conditions: '#/spp', uri_privacy: '#/privacy'})"></span>
                    </b-form-checkbox>
                    <b-form-invalid-feedback
                        :state="validAgreement"
                    >
                        <div v-html="$t('auth.agreement_warning')"/>
                    </b-form-invalid-feedback>
                </b-form-group>
                <!-- receivingNews -->
                <b-form-group
                    id="group-receivingNews"
                    class="mt-3"
                    label=""
                    label-for="receivingNews"
                >
                    <b-form-checkbox
                        id="receivingNews"
                        v-model="record.receivingNews"
                    >
                        <span v-html="$t('auth.receiving_news')"></span>
                        <b-button
                            v-b-toggle.collapse-help-keyword
                            class="ml-1 py-0" size="sm"
                            variant="info"
                        >?
                        </b-button>
                    </b-form-checkbox>
                    <!-- collapse:message receiving news -->
                    <b-collapse id="collapse-help-keyword">
                        <b-card class="bg-info __help_message">
                            <div v-html="$t('auth.message_receiving_news')"></div>
                        </b-card>
                    </b-collapse>
                </b-form-group>
            </b-form>
        </b-overlay>
        <!-- alert error -->
        <b-alert
            :show="errors.signupMessage != null"
            class="mb-0"
            dismissible
            variant="danger"
            @dismissed="resetErrors"
        >
            <div v-html="formatSignupError"></div>
        </b-alert>
    </b-modal>
</template>

<script>

export default {
    name: "ModalFormRegistration",
    components: {},
    props: {
        componentKey: {
            type: String,
            default: ''
        },
        uri: null,
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            record: {
                email: '',
                password: '',
                showPassword: false,
                agreement: false,
                receivingNews: false,
            },
            form: {
                valid: true,
            },
            errors: {
                signupMessage: null,
            },
        }
    },
    mounted() {
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        resetErrors() {
            this.errors.signupMessage = null;
        },
        // <-- modal -->
        modalOpen() {
            this.resetErrors();
        },
        modalClose() {
            // this.showOverlay(false);
        },
        hideModal() {
            // <-- hide the modal manually -->
            this.$nextTick(() => {
                this.$bvModal.hide('modalFormRegistration' + this.componentKey)
            });
        },
        // <-- form -->
        submit() {
            this.form.valid = this.$refs.form.checkValidity() && this.record.agreement;
        },
        save(bvModalEvent) {
            this.signupMessage = null;
            bvModalEvent.preventDefault();
            this.submit();
            if (!this.form.valid) {
                return;
            }
            // <-- axios -->
            this.showOverlay(true);

            let href = location.protocol + '//' + location.host + '/#/';
            // <-- axios -->
            this.axios.post(this.uri.main + '/signup',
                {
                    'locale': this.$i18n.locale,
                    'href': href,
                    'email': this.record.email.toLowerCase(),
                    'password': this.record.password,
                })
                .then((response) => {
                    this.hideModal();
                    this.showOverlay(false);

                    this.msgBoxRegistration();
                })
                .catch((error) => {
                    error = error.response.data;
                    this.errors.signupMessage = error;
                    this.showOverlay(false);
                })
                .finally(() => {
                });
        },
        // <-- message boxes -->
        msgBoxRegistration() {
            // <-- HTML string -->
            const h = this.$createElement;
            const titleVNode = h('div',
                {
                    domProps: {innerHTML: this.$t('auth.register')}
                });
            const messageVNode = h('div',
                {
                    domProps: {innerHTML: this.$t('auth.register_message')}
                });
            // <-- modal message box -->
            this.$bvModal.msgBoxOk([messageVNode],
                {
                    title: [titleVNode],
                    size: 'md',
                    headerBgVariant: 'info',
                    footerBgVariant: 'info',
                    buttonSize: 'sm',
                    okVariant: 'primary',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true,
                })
                .then(value => {
                    // this.$router.push('/');
                })
                .catch(error => {
                });
        }
    },
    computed: {
        // <-- errors -->
        formatSignupError() {
            let message = '';
            // <--  -->
            let error = this.errors.signupMessage;
            if (error === null) {
                return null;
            }
            // <-- error.message -->
            if (error.message !== undefined) {
                message = error.message;
            }
            // <-- error.errors -->
            if (error.errors !== undefined && typeof error.errors === 'object') {
                for (let key in error.errors) {
                    message += '<br><b>' + key + ':</b> ' + error.errors[key][0];
                }
            }
            // <--  -->
            return message;
        },
        // <-- valid -->
        validEmail: function () {
            if (this.form.valid) {
                return null;
            }
            let re = /^(([^<>()[\]\\.,;ščćžđ:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let valid = re.test(this.record.email);
            return valid ? null : false;
        },
        validPassword() {
            if (this.form.valid) {
                return null;
            }
            let value = this.record.password;
            return value == null || value.length < 6 ? false : null;
        },
        validAgreement() {
            if (this.form.valid) {
                return null;
            }
            return this.record.agreement ? null : false;
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
