<template>
    <div v-resize @resize="layoutResize">
        <!-- big screens -->
        <a href="/#/" style="text-decoration: none">

            <b-jumbotron bg-variant="secondary"
                         text-variant="primary"
                         style="margin-bottom: 10px"
                         class="d-none d-md-block py-2 px-4"
            >
                <template #header class="shadow">
                    {{$t('pnd')}}
                </template>


                <!--  MINI CART  -->
                <b-card v-if="showDiv"
                        no-body
                        body-bg-variant="secondary"
                        class="mini-cart active"
                >

                    <!--  MINI CART header  -->
                    <b-card-header header-bg-variant="primary"
                                   class="text-center"
                                   header-text-variant="white"
                    >
                        <b-row align-h="between">
                            <b-col cols="11">
                                <h4 class="mb-0">
                                    <b-icon-basket class="pb-1 mr-3"></b-icon-basket>
                                    Mini košarica
                                </h4>
                            </b-col>
                            <b-col cols="1">
                                <b-button-close class="close" text-variant="white" aria-label="close"
                                                @click="hideMiniCart()">
                                    <span aria-hidden="true" class="font-weight-bold">&times;</span>
                                </b-button-close>
                            </b-col>
                            <!--                    <b-col cols="2">2</b-col>-->
                        </b-row>

                    </b-card-header>
                    <b-card-body style="padding: 0">

                        <!--   MINI CART products  -->
                        <b-card no-body>
                            <b-row no-gutters align-v="center">
                                <b-col cols="2"><img src="../../../assets/images/zelenjava.jpeg" height="auto"
                                                     width="60"/></b-col>
                                <b-col cols="4">Rjavi šampinjoni
                                </b-col>
                                <b-col cols="1">1 x</b-col>
                                <b-col cols="2" class="text-center">0,5 kg</b-col>
                                <b-col cols="2" class="text-right">2,28 €</b-col>
                                <b-col cols="1">
                                    <b-button-close class="close" text-variant="black" aria-label="close">
                                        <span aria-hidden="true">&times;</span>
                                    </b-button-close>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-card-body>

                    <!--   MINI CART footer  -->
                    <b-card-footer footer-bg-variant="primary"
                                   footer-text-variant="white"
                                   style="padding: 0"
                    >
                        <b-row style="padding: 10px" align-h="between">
                            <b-col cols="6"><b>SKUPAJ:</b></b-col>
                            <b-col cols="6" class="text-right"><b>2,28 €</b></b-col>
                        </b-row>
                        <b-row no-gutters>
                            <b-col cols="6"><b>
                                <b-button block squared>V KOŠARICO</b-button>
                            </b></b-col>
                            <b-col cols="6"><b>
                                <b-button variant="danger" block squared>NA BLAGAJNO</b-button>
                            </b></b-col>
                        </b-row>
                    </b-card-footer>
                </b-card>

            </b-jumbotron>

            <!-- smaller screens -->
            <b-jumbotron bg-variant="secondary"
                         text-variant="primary"
                         style="margin-bottom: 10px"
                         :header="$t('pnd')"
                         header-level="4"
                         class="d-md-none d-block p-3 pl-4"
            >
            </b-jumbotron>
        </a>
    </div>
</template>

<script>
import CartButton from "@/components/_public/cart/CartButton";
//@group LAYOUT
export default {
    name: "AppHeader",
    components: {CartButton},
    props: {},
    data() {
        return {
            uri: {
                main: `api/v1/_public`,
            },
            showDiv: false,
            locales: {},
        }
    },
    mounted() {
        // this.setActive();
        this.loadLocales();
        this.getLocale();
    },
    methods: {
        // <-- layout -->
        layoutResize(e) {
            this.__layoutTopAdd(this.$options.name, e.detail.width, e.detail.height);
        },
        // <--  -->
        showMiniCart() {
            this.showDiv = !this.showDiv;
        },
        hideMiniCart() {
            this.showDiv = false;
        },
        setActive() {
            let route = this.$router.currentRoute.path;
            let paths = route.split('/');
            route = paths[1];

            if (route === '') { // <-- home -->
                this.items.forEach(item => {
                    item.active = item.path === route + '/';
                });
                return;
            }
            this.items.forEach(item => {
                item.active = item.path.includes(route);
            });
        },
        // <-- locale -->
        loadLocales() {
            this.axios.get(this.uri.main + `/getLocales`)
                .then((response) => {
                    this.locales = response.data;
                    this.setGlobalLocaleContent(response.data);
                })
                .catch((error) => {
                    // console.log("error:", error);
                })
                .finally(function () {
                    // always executed
                });
        },
        getLocale() {
            // console.log("navigator:", navigator.language.split('-')[0] || process.env.VUE_APP_I18N_LOCALE || 'en');
            if (localStorage.locale == null) {
                return;
            }
            this.$set(this.$i18n, 'locale', localStorage.locale);
        },
        setLocale(locale) {
            this.$set(this.$i18n, 'locale', locale.locale);
            localStorage.locale = locale.locale;
            location.reload();
        },
        setGlobalLocaleContent(locales) {
            locales.every(v => {
                if (v.locale === this.$i18n.locale) {
                    this.__localeContent = v.content;
                    return false;
                }
                return true;
            });

        }
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
