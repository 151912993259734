<template>
    <b-button
        :variant="getCartVariant"
        :title="$t('cart.noLogin_message')"
        :size="size"
        to="/cart"
    >
        <b-icon-basket></b-icon-basket>
        <b-badge class="cart-number ml-1 text-black-50" pill active variant="info">
            {{$root.mx_cartCount}}
            <span class="sr-only">Izdelkov v košarici</span>
        </b-badge>
    </b-button>
</template>

<script>
export default {
    name: "CartButton",
    components: {},
    props: {
        size:{
            type: String
        }
    },
    data() {
        return {

        }
    },
    mounted() {
        this.__cartCount();
    },
    methods: {

    },
    computed: {
        // <-- cart -->
        isCartDisabled() {
            return !this.$root.mx_isLogin;
        },
        getCartVariant() {
            if (this.isCartDisabled) {
                return 'light';
            }
            return 'primary';
        }
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
