<template>
    <div v-resize @resize="layoutResize" class="sticky-top">
        <b-navbar class="d-lg-flex d-none menu-main" type="light" variant="primary">
            <b-navbar-nav>
                <!-- version -->
                <div v-if="menusVisible.system"
                     class="version ml-2 px-1"
                     :class="getVersion.class"
                >
                    {{getVersion.version}}
                </div>
                <!--  -->
                <div v-for="item in getMenuItems">
                    <b-nav-item
                        v-if="item.visible === undefined || item.visible"
                        :active="item.active === true"
                        :disabled="item.disabled"
                        :to="item.path"
                    >
                        {{$t(`${item.text}`)}}
                    </b-nav-item>
                </div>
                <!-- dropdown: market -->
                <b-nav-item-dropdown :text="$t('menu.market')">
                    <b-dropdown-item to="/suppliers/null">{{$t('menu.providers')}}</b-dropdown-item>
                    <b-dropdown-item to="/market/null">{{$tc('public.product.main', 1)}}</b-dropdown-item>
                </b-nav-item-dropdown>
                <!-- dropdown: adventure admin -->
                <b-nav-item-dropdown :text="$t('menu.adventure.main')"
                                     v-if="menusVisible.system"
                >
                    <b-dropdown-item to="/_adventures2">{{$t('menu.adventure.adventures')}} 2</b-dropdown-item>
                    <b-dropdown-item to="/_adventure">{{$t('menu.adventure.adventures')}} ({{$t('form.deprecated')}})
                    </b-dropdown-item> <!-- // DEPRECATED -->
                </b-nav-item-dropdown>
                <!-- adventure public -->
                <b-nav-item
                    v-if="!menusVisible.system"
                    to="/_adventures2"
                >
                    {{$t('menu.adventure.adventures')}}
                </b-nav-item>
            </b-navbar-nav>
            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <!-- per page -->
                <b-form-select
                    v-show="$root.mx_pagination.visible"
                    v-model="$root.mx_pagination.perPage"
                    :options="mx_pagination.options"
                    class="mx-1"
                    size="sm"
                    style="width: 65px"
                ></b-form-select>
            </b-navbar-nav>

            <!-- button toolbar -->
            <b-button-toolbar>
                <!-- cart -->
                <CartButton class="ml-1"></CartButton>
                <!-- locales -->
                <b-dropdown
                    v-if="!$root.__isProductionMode"
                    class="d-flex justify-content-center align-items-center"
                    right variant="primary"
                >
                    <!-- button icon -->
                    <template v-slot:button-content>
                        <b-icon
                            aria-hidden="false"
                            icon="flag"
                            variant="secondary"
                        ></b-icon>
                    </template>
                    <!-- title -->
                    <b-dropdown-text class="h5 text-secondary font-weight-bold">
                        <h4 class="text-secondary mb-0">{{$t('menu.language')}}</h4>
                    </b-dropdown-text>
                    <b-dropdown-divider></b-dropdown-divider>
                    <!-- content-->
                    <div v-for="locale in locales">
                        <b-dropdown-item-button
                            value="locale.locale"
                            @click="setLocale(locale)"
                            :active="$i18n.locale === locale.locale"
                        >
                            {{locale.language}}
                        </b-dropdown-item-button>
                    </div>
                </b-dropdown>
                <!-- books -->
                <b-dropdown
                    class="d-flex justify-content-center align-items-center"
                    right variant="primary"
                >
                    <!-- button icon -->
                    <template v-slot:button-content>
                        <b-icon
                            aria-hidden="false"
                            icon="book-half"
                            variant="secondary"
                        ></b-icon>
                    </template>
                    <b-dropdown-item href="books/prlekijanadlani.pdf" target="_blank">Prlekija na dlani (pdf)</b-dropdown-item>
                        <b-dd-divider></b-dd-divider>
                    <b-dropdown-item href="https://www.youtube.com/watch?v=DjCMljIhLww" target="_blank">Prleška pustolovščina (youtube video)</b-dropdown-item>
                    <b-dropdown-item href="https://www.youtube.com/watch?v=YxxIbKagFOc" target="_blank">Igriva Prlekija ( youtube video)</b-dropdown-item>
                </b-dropdown>
                <!-- menu admin -->
                <b-dropdown
                    v-if="menusVisible.system"
                    class="d-flex justify-content-center align-items-center ml-1"
                    right
                    variant="primary"
                >
                    <!-- button icon -->
                    <template v-slot:button-content>
                        <b-icon
                            aria-hidden="true"
                            icon="tools"
                            variant="secondary"
                        ></b-icon>
                    </template>
                    <!-- themes -->
                    <!--<b-dropdown-text class="h5 text-secondary font-weight-bold">
                        Teme
                    </b-dropdown-text>
                    <b-dropdown-divider></b-dropdown-divider>
                    &lt;!&ndash; content&ndash;&gt;
                    <b-dropdown-item-button>
                        <b-icon-droplet scale="0.6"></b-icon-droplet>
                        {{$t('theme.spring')}}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button>
                        <b-icon-brightness-high scale="0.6"></b-icon-brightness-high>
                        {{$t('theme.summer')}}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button>
                        <b-icon-cloud scale="0.6"></b-icon-cloud>
                        {{$t('theme.fall')}}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button>
                        <b-icon-asterisk scale="0.6"></b-icon-asterisk>
                        {{$t('theme.winter')}}
                    </b-dropdown-item-button>
                    <b-dropdown-divider></b-dropdown-divider>-->

                    <!-- admin -->
                    <b-dropdown-text>
                        <h4 class="text-secondary mb-0">Admin</h4>
                    </b-dropdown-text>
                    <b-dropdown-divider></b-dropdown-divider>
                    <!-- content-->
                    <!--                    <b-dropdown-item href="http://pillars:26868/api/documentation" target="_blank">Dokumentacija API</b-dropdown-item>-->
                    <b-dropdown-item to="/admin">{{$t('menu.admin')}}</b-dropdown-item>
                    <b-dropdown-item to="/trade">{{$t('menu.trade')}}</b-dropdown-item>
                    <b-dropdown-item to="/adventure">{{$t('menu.adventure.adventures')}}</b-dropdown-item>
                    <b-dropdown-item to="/business">{{$t('menu.business')}}</b-dropdown-item>
                    <b-dropdown-item to="/register">{{$t('menu.register')}}</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <!-- developer -->
                    <b-dropdown-text>
                        <h4 class="text-secondary mb-0">Developer</h4>
                    </b-dropdown-text>
                    <b-dropdown-divider></b-dropdown-divider>
                    <!-- sandbox-->
                    <b-dropdown-item to="/developer/sandboxs">{{$t('menu.sandbox_s')}}</b-dropdown-item>
                    <b-dropdown-item to="/developer/sandboxm">{{$t('menu.sandbox_m')}}</b-dropdown-item>
                    <b-dropdown-item to="/developer/testing">{{$t('menu.testing')}}</b-dropdown-item>
                    <b-dropdown-item to="/developer/testing2">{{$t('menu.testing')}} 2</b-dropdown-item>
                    <b-dropdown-item to="/developer/testing3">{{$t('menu.testing')}} 3</b-dropdown-item>
                    <b-dropdown-item to="/developer/foldingCard">FoldingCard</b-dropdown-item>
                    <b-dropdown-item to="/developer/community">{{$t('menu.community')}}</b-dropdown-item>
                    <b-dropdown-item to="/developer/theme_preview">{{$t('menu.theme_preview')}}</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item to="/developer/vue_leaflet">Vue-leaflet</b-dropdown-item>
                    <b-dropdown-item to="/developer/leaflet_js">Leaflet js</b-dropdown-item>
                </b-dropdown>
            </b-button-toolbar>

            <b-navbar-nav>
                <!--                <b-button-group class="ml-1">-->

                <!-- themes -->
                <!--<b-nav-item-dropdown :text="$t('menu.theme')" right>
                    &lt;!&ndash; button icon &ndash;&gt;
                    <template v-slotBrisi:button-content>
                        <b-icon aria-hidden="true" icon="brightness-high" variant="secondary"></b-icon>
                    </template>
                    &lt;!&ndash; title &ndash;&gt;
                    <b-dropdown-text class="h5 text-secondary font-weight-bold">
                        Teme
                    </b-dropdown-text>
                    <b-dropdown-divider></b-dropdown-divider>
                    &lt;!&ndash; content&ndash;&gt;
                    <b-dropdown-item-button>
                        <b-icon-droplet scale="0.6"></b-icon-droplet>
                        {{$t('theme.spring')}}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button>
                        <b-icon-brightness-high scale="0.6"></b-icon-brightness-high>
                        {{$t('theme.summer')}}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button>
                        <b-icon-cloud scale="0.6"></b-icon-cloud>
                        {{$t('theme.fall')}}
                    </b-dropdown-item-button>
                    <b-dropdown-item-button>
                        <b-icon-asterisk scale="0.6"></b-icon-asterisk>
                        {{$t('theme.winter')}}
                    </b-dropdown-item-button>
                </b-nav-item-dropdown>-->

                <!-- locales -->
                <!-- <b-nav-item-dropdown :text="language" right>
                     &lt;!&ndash; button icon &ndash;&gt;
                     <template v-slotBrisi:button-content>
                         <b-icon aria-hidden="true" icon="flag" variant="secondary"></b-icon>
                     </template>
                     &lt;!&ndash; title &ndash;&gt;
                     <b-dropdown-text class="h5 text-secondary font-weight-bold">
                         Jezik
                     </b-dropdown-text>
                     <b-dropdown-divider></b-dropdown-divider>
                     &lt;!&ndash; content&ndash;&gt;
                     <div v-for="locale in locales">
                         <b-dropdown-item-button value="locale.locale" @click="setLocale(locale)">{{locale.language}}
                         </b-dropdown-item-button>
                     </div>
                 </b-nav-item-dropdown>-->  <!-- // TODO deprecated -->


                <!-- locales -->
                <!--                    <b-nav-item-dropdown-->
                <!--                        class="d-flex justify-content-center align-items-center"-->
                <!--                        right-->
                <!--                    >-->
                <!--                        &lt;!&ndash; button icon &ndash;&gt;-->
                <!--                        <template v-slotBrisi:button-content>-->
                <!--                            <b-icon-->
                <!--                                aria-hidden="false"-->
                <!--                                icon="flag"-->
                <!--                                variant="secondary"-->
                <!--                            ></b-icon>-->
                <!--                        </template>-->
                <!--                        &lt;!&ndash; title &ndash;&gt;-->
                <!--                        <b-dropdown-text-->
                <!--                            class="h5 text-secondary font-weight-bold"-->
                <!--                        >-->
                <!--                            <h4 class="text-secondary mb-0">{{$t('menu.language')}}</h4>-->
                <!--                        </b-dropdown-text>-->
                <!--                        <b-dropdown-divider></b-dropdown-divider>-->
                <!--                        &lt;!&ndash; content&ndash;&gt;-->
                <!--                        <div v-for="locale in locales">-->
                <!--                            <b-dropdown-item-button-->
                <!--                                value="locale.locale"-->
                <!--                                @click="setLocale(locale)"-->
                <!--                                :active="$i18n.locale === locale.locale"-->
                <!--                            >-->
                <!--                                {{locale.language}}-->
                <!--                            </b-dropdown-item-button>-->
                <!--                        </div>-->
                <!--                    </b-nav-item-dropdown>-->

                <!-- mini cart -->
                <!--                    <b-button-group>-->
                <!--                    <CartButton></CartButton>-->
                <!--                    </b-button-group>-->


                <!--                    &lt;!&ndash; menu admin &ndash;&gt;-->
                <!--                    <b-nav-item-dropdown-->
                <!--                        v-if="menusVisible.system"-->
                <!--                        class="d-flex justify-content-center align-items-center"-->
                <!--                        right-->
                <!--                    >-->
                <!--                        &lt;!&ndash; button icon &ndash;&gt;-->
                <!--                        <template v-slotBrisi:button-content>-->
                <!--                            <b-icon-->
                <!--                                aria-hidden="true"-->
                <!--                                icon="tools"-->
                <!--                                variant="secondary"-->
                <!--                            ></b-icon>-->
                <!--                        </template>-->
                <!--                        &lt;!&ndash; themes &ndash;&gt;-->
                <!--                        &lt;!&ndash;<b-dropdown-text class="h5 text-secondary font-weight-bold">-->
                <!--                            Teme-->
                <!--                        </b-dropdown-text>-->
                <!--                        <b-dropdown-divider></b-dropdown-divider>-->
                <!--                        &lt;!&ndash; content&ndash;&gt;-->
                <!--                        <b-dropdown-item-button>-->
                <!--                            <b-icon-droplet scale="0.6"></b-icon-droplet>-->
                <!--                            {{$t('theme.spring')}}-->
                <!--                        </b-dropdown-item-button>-->
                <!--                        <b-dropdown-item-button>-->
                <!--                            <b-icon-brightness-high scale="0.6"></b-icon-brightness-high>-->
                <!--                            {{$t('theme.summer')}}-->
                <!--                        </b-dropdown-item-button>-->
                <!--                        <b-dropdown-item-button>-->
                <!--                            <b-icon-cloud scale="0.6"></b-icon-cloud>-->
                <!--                            {{$t('theme.fall')}}-->
                <!--                        </b-dropdown-item-button>-->
                <!--                        <b-dropdown-item-button>-->
                <!--                            <b-icon-asterisk scale="0.6"></b-icon-asterisk>-->
                <!--                            {{$t('theme.winter')}}-->
                <!--                        </b-dropdown-item-button>-->
                <!--                        <b-dropdown-divider></b-dropdown-divider>&ndash;&gt;-->

                <!--                        &lt;!&ndash; admin &ndash;&gt;-->
                <!--                        <b-dropdown-text>-->
                <!--                            <h4 class="text-secondary mb-0">Admin</h4>-->
                <!--                        </b-dropdown-text>-->
                <!--                        <b-dropdown-divider></b-dropdown-divider>-->
                <!--                        &lt;!&ndash; content&ndash;&gt;-->
                <!--                        &lt;!&ndash;                    <b-dropdown-item href="http://pillars:26868/api/documentation" target="_blank">Dokumentacija API</b-dropdown-item>&ndash;&gt;-->
                <!--                        <b-dropdown-item to="/admin">{{$t('menu.admin')}}</b-dropdown-item>-->
                <!--                        <b-dropdown-item to="/trade">{{$t('menu.trade')}}</b-dropdown-item>-->
                <!--                        <b-dropdown-item to="/business">{{$t('menu.business')}}</b-dropdown-item>-->
                <!--                        <b-dropdown-item to="/register">{{$t('menu.register')}}</b-dropdown-item>-->
                <!--                        <b-dropdown-divider></b-dropdown-divider>-->
                <!--                        &lt;!&ndash; developer &ndash;&gt;-->
                <!--                        <b-dropdown-text>-->
                <!--                            <h4 class="text-secondary mb-0">Developer</h4>-->
                <!--                        </b-dropdown-text>-->
                <!--                        <b-dropdown-divider></b-dropdown-divider>-->
                <!--                        &lt;!&ndash; content&ndash;&gt;-->
                <!--                        <b-dropdown-item to="/sandboxs">{{$t('menu.sandbox_s')}}</b-dropdown-item>-->
                <!--                        <b-dropdown-item to="/sandboxm">{{$t('menu.sandbox_m')}}</b-dropdown-item>-->
                <!--                        <b-dropdown-item to="/community">{{$t('menu.community')}}</b-dropdown-item>-->
                <!--                        <b-dropdown-item to="/theme_preview">{{$t('menu.theme_preview')}}</b-dropdown-item>-->
                <!--                    </b-nav-item-dropdown>-->

                <!-- admin -->
                <!--  <b-nav-item-dropdown :text="$t('menu.admin')" right>
                      &lt;!&ndash; button icon &ndash;&gt;
                      <template v-slotBrisi:button-content>
                          <b-icon aria-hidden="true" icon="laptop" variant="secondary"></b-icon>
                      </template>
                      &lt;!&ndash; title &ndash;&gt;
                      <b-dropdown-text class="h5 text-secondary font-weight-bold">
                          Admin
                      </b-dropdown-text>
                      <b-dropdown-divider></b-dropdown-divider>
                      &lt;!&ndash; content&ndash;&gt;
                      &lt;!&ndash;                    <b-dropdown-item href="http://pillars:26868/api/documentation" target="_blank">Dokumentacija API</b-dropdown-item>&ndash;&gt;
                      <b-dropdown-item to="/admin">{{$t('admin.menu')}}</b-dropdown-item>
                      <b-dropdown-item to="/register">{{$t('register.menu')}}</b-dropdown-item>
                  </b-nav-item-dropdown>-->
                <!-- dropdownUser -->
                <DropdownUser
                    :parent-component-name="$options.name"
                ></DropdownUser>
                <!--                </b-button-group>-->
            </b-navbar-nav>
        </b-navbar>
    </div>
</template>

<script>
import DropdownUser from "@/components/authentication/user/DropdownUser";
import SubMenuMainSm from "@/components/general/layout/menu/main/SubMenuMainSm";
import CartButton from "@/components/_public/cart/CartButton";

export default {
    name: "MenuMain",
    components: {CartButton, SubMenuMainSm, DropdownUser},
    props: {},
    data() {
        return {
            uri: {
                main: `api/v1/_public`,
            },
            locales: {},
            menusVisible: {
                manager: false,
                system: false,
                admin: false,
            }
        };
    },
    mounted() {
        this.setActive();
        this.loadLocales();
        this.getLocale();
    },
    methods: {
        layoutResize(e) {
            this.__layoutTopAdd(this.$options.name, e.detail.width, e.detail.height);
        },
        // <--  -->
        setActive() {
            let route = this.$router.currentRoute.path;
            let paths = route.split('/');
            route = paths[1];

            if (route === '') { // <-- home -->
                this.getMenuItems.forEach(item => {
                    item.active = item.path === route + '/';
                });
                return;
            }
            this.getMenuItems.forEach(item => {
                item.active = item.path.includes(route);
            });
        },
        setMenusVisible() {
            // <-- menu manager -->
            this.menusVisible.manager = this.__checkPermission('sale', ['read', 'write']);
            // <-- menu admin -->
            this.menusVisible.system = this.__checkRoles(['sadmin', 'admin']);
            this.menusVisible.admin = this.__checkRole('admin');
        },
        // <-- locale -->
        loadLocales() {
            this.axios.get(this.uri.main + `/getLocales`)
                .then((response) => {
                    this.locales = response.data;
                })
                .catch((error) => {
                    // console.log("error:", error);
                })
                .finally(function () {
                    // always executed
                });
        },
        getLocale() {
            // console.log("navigator:", navigator.language.split('-')[0] || process.env.VUE_APP_I18N_LOCALE || 'en');
            if (localStorage.locale == null) {
                return;
            }
            this.$set(this.$i18n, 'locale', localStorage.locale);
        },
        setLocale(locale) {
            this.$set(this.$i18n, 'locale', locale.locale);
            localStorage.locale = locale.locale;
            location.reload();
        },
    },
    computed: {
        language: {
            get() {
                for (let index in this.locales) {
                    if (this.locales[index].locale === this.$i18n.locale) {
                        return this.locales[index].language;
                    }
                }
                return this.$t('menu.language');
            }
        },
        getMenuItems() {
            return [
                {
                    text: 'menu.home',
                    path: '/',
                    disabled: false,
                    active: false,
                },
                {
                    text: 'menu.prlekija',
                    path: '/_prlekija/',
                    disabled: false,
                    active: false,
                },
                // {
                //     text: 'menu.providers',
                //     path: '/suppliers/null',
                //     disabled: false,
                //     active: false,
                // },
                // {
                //     text: 'menu.market',
                //     path: '/market/null',
                //     disabled: false,
                //     active: false,
                // },
                {
                    text: 'menu.manager',
                    path: '/manager/',
                    visible: this.menusVisible.manager,
                    disabled: false,
                    active: false,
                },
            ];
        },
        getVersion() {
            let hostname = window.location.hostname;
            // <--  -->
            let classType = 'bg-secondary';
            switch (hostname) {
                case 'localhost':
                    classType = 'bg-info text-secondary'
                    break;
                case 'test.prlekijanadlani.si':
                    classType = 'bg-danger text-white'
                    break;
                case 'www.prlekijanadlani.si':
                    classType = 'bg-secondary text-primary'
                    break;
            }
            // <--  -->
            return {
                version: `${hostname.split('.')[0]}: ${this.$mainVersion}`,
                class: classType
            }

        }
    },
    filters: {},
    watch: {
        $route(to, from) {
            this.setActive();
        },
        '$root.mx_isLogin': {
            handler: function (value, oldValue) {
                this.setMenusVisible();
            },
            deep: true
        },
    }
}
</script>

<style scoped>
.version {
    /*background: #fd7e14;*/
    position: absolute;
    /*top: 45px;*/
    /*left: 2px;*/
    font-size: 9pt;
    /*right: 5%;*/
    bottom: 2px;
    border-radius: 5px;
    /*opacity: 0.9;*/
    /*z-index: 1000;*/
    /*padding: 0px 4px 0px 4px;*/
}
</style>
