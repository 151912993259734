import Home from "@/views/Home";

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
    },

    // <-- _prlekija -->
    {
        path: '/_prlekija',
        component: () => import('@/components/_public/_prlekija/PrlekijaContent'),
        children: [
            {
                path: '',
                component: () => import('@/components/_public/_prlekija/Prlekija'),
            },
        ]
    },


    // <-- market -->
    {
        path: '/market/:type_id',
        component: () => import('@/components/_public/_product/ProductContent.vue'),
        children: [
            {
                path: '',
                component: () => import('@/components/_public/_product/Products.vue'),
            },
        ]
    },

    {
        path: '/suppliers/:group_id',
        component: () => import('@/components/_public/supplier/SuppliersContent'),
        children: [
            {
                path: '',
                component: () => import('@/components/_public/supplier/Suppliers'),
            },
        ]
    },
        // <-- _adventure -->
        {
            path: '/_adventure',
            component: () => import('@/components/_public/_adventure/Adventure'),
            // children: [
            //     {
            //         path: '',
            //         component: () => import('@/components/_public/supplier/Suppliers'),
            //     },
            // ]
        },
        // <-- _adventures2 -->
        {
            path: '/_adventures2',
            component: () => import('@/components/_public/_adventures2/Adventures2Main'),
            children: [

                {
                    path: '',
                    component: () => import('@/components/_public/_adventures2/adventures/Adventures'),
                },

                {
                    path: 'create-route',
                    component: () => import('@/components/_public/_adventures2/createRoute/CreateRoute'),
                },
                {
                    path: 'booked',
                    component: () => import('@/components/_public/_adventures2/booked/Booked'),
                },
            ]
        },

        // <-- let' go -->
        {
            path: '/let-s-go',
            component: () => import('@/components/_public/let-s-go/LetsGo'),
            children: [
                {
                    path: ':route',
                    component: () => import('@/components/_public/let-s-go/LetsGoDestinations'),
                },
                {
                    path: ':route/:destination',
                    component: () => import('@/components/_public/let-s-go/Let-s-goExperiences'),
                },
                {
                    path: ':route/:destination/:experience/:type',
                    component: () => import('@/components/_public/let-s-go/LetsGoExperience'),
                },
                {
                    path: ':route/:destination/:experience/:type/:task',
                    component: () => import('@/components/_public/let-s-go/LetsGoTask'),
                },
            ]
        },

    // <-- cart_deprecated -->
    {
        path: '/cart_deprecated',
        component: () => import('@/components/cart_deprecated/main/CartContent_deprecated'),
        children: [
            {
                path: '',
                component: () => import('@/components/cart_deprecated/main/Cart_deprecated'),
            },
        ]
    },

    // <-- cart -->
    {
        path: '/cart',
        component: () => import('@/components/_public/cart/CartContent'),
        children: [
            {
                path: '',
                component: () => import('@/components/_public/cart/Cart'),
            },
        ]
    },
    // <-- manager -->
    {
        path: '/manager',
        component: () => import('@/components/manager/main/ManagerContent'),
        children: [
            {
                path: '',
                component: () => import('@/components/manager/main/Manager'),
            },
            {
                path: 'profile',
                component: () => import('@/components/manager/profile/Profile'),
            },
            {
                path: 'personal_data',
                component: () => import('@/components/manager/personalData/PersonalData'),
            },
            {
                path: 'organisation',
                component: () => import('@/components/manager/organisation/Organisation'),
            },
            {
                path: 'products',
                component: () => import('@/components/manager/product/Products'),
            },
            {
                path: 'ordered',
                component: () => import('@/components/manager/trade/Ordered'),
            },
            {
                path: 'sold',
                component: () => import('@/components/manager/trade/Sold'),
            },
        ]
    },

    // <-- admin -->
    {
        path: '/admin',
        component: () => import('@/components/admin/main/AdminContent'),
        children: [
            {
                path: '',
                component: () => import('@/components/admin/main/Admin'),
            },
            {
                path: 'locales',
                component: () => import('@/components/admin/locale/Locales'),
            },
            {
                path: 'projects',
                component: () => import('@/components/admin/project/Projects'),
            },
            {
                path: 'accesses',
                component: () => import('@/components/admin/access/Accesses'),
            },
            {
                path: 'rights',
                component: () => import('@/components/admin/right/Rights'),
            },
            {
                path: 'users',
                component: () => import('@/components/admin/user/Users'),
            },
            {
                path: 'organisations',
                component: () => import('@/components/admin/organisation/Organisations'),
            },
        ]
    },

    // <-- trade -->
    {
        path: '/trade',
        component: () => import('@/components/trade/main/TradeContent'),
        children: [
            {
                path: '',
                component: () => import('@/components/trade/main/Trade'),
            },
            {
                path: 'groups',
                component: () => import('@/components/trade/group/Groups'),
            },
            {
                path: 'types',
                component: () => import('@/components/trade/type/Types'),
            },
            {
                path: 'products',
                component: () => import('@/components/trade/product/Products'),
            },
            {
                path: 'articles',
                component: () => import('@/components/trade/article/Articles'),
            },
            {
                path: 'warehouses',
                component: () => import('@/components/trade/warehouse/Warehouses'),
            },
            {
                path: 'delivery_prices',
                component: () => import('@/components/trade/deliveryPrice/DeliveryPrice'),
            },
        ]
    },

    // <-- adventure -->
    {
        path: '/adventure',
        component: () => import('@/components/adventure/main/AdventureContent'),
        children: [
            {
                path: '',
                component: () => import('@/components/adventure/main/Adventure'),
            },
            {
                path: 'categories',
                component: () => import('@/components/adventure/category/Categories'),
            },
            {
                path: 'types',
                component: () => import('@/components/adventure/type/Types'),
            },
            {
                path: 'destinations',
                component: () => import('@/components/adventure/destination/Destinations'),
            },
            {
                path: 'experiences',
                component: () => import('@/components/adventure/experience/Experiences'),
            },
            {
                path: 'routes',
                component: () => import('@/components/adventure/route/Routes'),
            },
        ]
    },

    // <-- business -->
    {
        path: '/business',
        component: () => import('@/components/business/main/BusinessContent'),
        children: [
            {
                path: '',
                component: () => import('@/components/business/main/Business'),
            },
            {
                path: 'b2b_invoices',
                component: () => import('@/components/business/b2bInvoice/B2bInvoice'),
            },
            {
                path: 'b2c_invoices',
                component: () => import('@/components/business/b2cInvoice/B2cInvoice'),
            },
            {
                path: 'acceptances',
                component: () => import('@/components/business/acceptanceForm/AcceptanceForms'),
            },
            {
                path: 'deliveries',
                component: () => import('@/components/business/deliveryReceipt/DeliveryReceipts'),
            },
            {
                path: 'orders',
                component: () => import('@/components/business/orderForm/OrderForms'),
            },
            {
                path: 'returns',
                component: () => import('@/components/business/returnForm/ReturnForms'),
            },
            {
                path: 'offers',
                component: () => import('@/components/business/offerForm/OfferForms'),
            },
            {
                path: 'travel_orders',
                component: () => import('@/components/business/travelOrder/TravelOrders'),
            },
        ]
    },

    // <-- auth: other -->
    {
        path: '/confirm',
        component: () => import('@/components/authentication/other/Confirm'),
    },
    {
        path: '/forgotten_password',
        component: () => import('@/components/authentication/other/ResetPassword'),
    },

    // <-- register -->
    {
        path: '/register',
        component: () => import('@/components/register/main/RegisterContent.vue'),
        children: [
            {
                path: '',
                component: () => import('@/components/register/main/Register.vue'),
            },
            {
                path: 'currencies',
                component: () => import('@/components/register/currency/Currencies.vue'),
            },
            {
                path: 'continents',
                component: () => import('@/components/register/continent/Continents'),
            },
            {
                path: 'states',
                component: () => import('@/components/register/state/States.vue'),
            },
            {
                path: 'post_offices',
                component: () => import('@/components/register/post_office/PostOffices.vue'),
            },
            {
                path: 'banks',
                component: () => import('@/components/register/bank/Banks.vue'),
            },
            {
                path: 'bank_purpose_codes',
                component: () => import('@/components/register/bank_purpose_code/BankPurposeCodes.vue'),
            },
            {
                path: 'tax_rates',
                component: () => import('@/components/register/tax_rate/TaxRates.vue'),
            },
            {
                path: 'tax_types',
                component: () => import('@/components/register/taxpayer_type/TaxpayerTypes.vue'),
            },
            {
                path: 'transaction_types',
                component: () => import('@/components/register/transaction_type/TransactionTypes.vue'),
            },
            {
                path: 'document_types',
                component: () => import('@/components/register/document_type/DocumentTypes.vue'),
            },
            {
                path: 'event_types',
                component: () => import('@/components/register/event_type/EventTypes.vue'),
            },
            {
                path: 'requirements',
                component: () => import('@/components/register/requirement/Requirements'),
            },
            {
                path: 'offer_types',
                component: () => import('@/components/register/offer_type/OfferTypes'),
            },
            {
                path: 'partnership_types',
                component: () => import('@/components/register/partnership_type/PartnershipTypes'),
            },
            {
                path: 'packaging',
                component: () => import('@/components/register/packing/Packaging'),
            },
            {
                path: 'production_types',
                component: () => import('@/components/register/production_type/ProductionTypes'),
            },
            {
                path: 'organisation_types',
                component: () => import('@/components/register/organisation_type/OrganisationTypes'),
            },
            {
                path: 'unit_groups',
                component: () => import('@/components/register/unit_group/UnitGroups'),
            },
            {
                path: 'units_basic',
                component: () => import('@/components/register/unit_basic/UnitsBasic'),
            },
            {
                path: 'regions',
                component: () => import('@/components/register/region/Regions'),
            },
        ]
    },
    // <-- developer -->
    {
        path: '/developer/sandboxs',
        component: () => import('@/components/developer/SandboxS'),
    },
    {
        path: '/developer/sandboxm',
        component: () => import('@/components/developer/SandboxM'),
    },
    {
        path: '/developer/testing',
        component: () => import('@/components/developer/Testing'),
    },
    {
        path: '/developer/testing2',
        component: () => import('@/components/developer/Testing2'),
    },
    {
        path: '/developer/testing3',
        component: () => import('@/components/developer/Testing3'),
    },
    {
        path: '/developer/foldingCard',
        component: () => import('@/components/developer/DevFoldingCard'),
    },
    {
        path: '/developer/community',
        component: () => import('@/components/developer/Community'),
    },
    {
        path: '/developer/theme_preview',
        component: () => import('@/components/developer/ThemePreview'),
    },
    // <-- developer leaflet -->
    {
        path: '/developer/vue_leaflet',
        component: () => import('@/components/developer/leaflet/VueLeaflet'),
    },
    {
        path: '/developer/leaflet_js',
        component: () => import('@/components/developer/leaflet/Leaflet_js'),
    },
    // <-- legal documents etc.  -->
    {
        path: '/about',
        component: () => import('@/components/general/legal/About'),
    },
    {
        path: '/contacts',
        component: () => import('@/components/general/legal/Contacts'),
    },
    {
        path: '/cookies',
        component: () => import('@/components/general/legal/Cookies'),
    },
    {
        path: '/howitworks',
        component: () => import('@/components/general/legal/HowItWorks'),
    },
    {
        path: '/privacy',
        component: () => import('@/components/general/legal/Privacy'),
    },
    {
        path: '/spp',
        component: () => import('@/components/general/legal/Spp'),
    },
];

export default routes;
