<template>
    <div class="sub-menu-sm p-1 text-center bg-primary-light">
        <b-dropdown-group>
            <!-- home -->
            <b-button
                class="mr-3"
                v-b-tooltip.hover.top="{variant: 'primary'}"
                :title="$t('menu.home')"
                to="/#/"
                @click="eventHide()"
            >
                <b-icon-house-door></b-icon-house-door>
            </b-button>
            <!-- user -->
            <!--            <b-button
                            class="mr-3"
                            v-b-tooltip.hover.top="{variant: 'primary'}"
                            :title="$t('menu.user')"
                            to="/manager/profile"
                        >
                            <b-icon-person></b-icon-person>
                        </b-button>-->
            <!-- cart -->
            <!--            <b-button
                            class="mr-0"
                            v-b-tooltip.hover.top="{variant: 'primary'}"
                            :title="$t('menu.cart')"
                            to="/cart"
                        >
                            <b-icon-basket></b-icon-basket>
                        </b-button>-->
        </b-dropdown-group>
        <b-dd-divider></b-dd-divider>
        <!-- Prlekija -->
        <b-dropdown-item to="/_prlekija">{{$t('menu.prlekija')}}</b-dropdown-item>
        <b-dd-divider></b-dd-divider>
        <!--        &lt;!&ndash; providers &ndash;&gt;-->
        <!--        <b-dropdown-item to="/suppliers/null">{{$t('menu.providers')}}</b-dropdown-item>-->
        <!--        <b-dd-divider></b-dd-divider>-->
        <!-- products -->
        <b-dropdown-item to="/market/null">{{$t('menu.market')}}</b-dropdown-item>
        <!-- adventure -->
        <b-dd-divider></b-dd-divider>
        <b-dropdown-item to="/_adventures2">{{$t('menu.adventure.adventures')}} 2</b-dropdown-item>
        <b-dd-divider></b-dd-divider>
        <b-dropdown-item
            v-if="menusVisible.system"
            to="/_adventure">{{$t('menu.adventure.adventures')}}<br>({{$t('form.deprecated')}})
        </b-dropdown-item
        ><!-- // DEPRECATED -->
        <b-dd-divider v-if="menusVisible.system"></b-dd-divider><!-- // DEPRECATED -->
    </div>
</template>

<script>
export default {
    name: "SubMenuMainSm",
    components: {},
    props: {},
    data() {
        return {
            menusVisible: {
                manager: false,
                system: false,
                admin: false,
            }
        }
    },
    mounted() {
    },
    methods: {
        eventHide() {
            this.$emit('eventHide');
        },
        setMenusVisible() {
            // <-- menu manager -->
            this.menusVisible.manager = this.__checkPermission('sale', ['read', 'write']);
            // <-- menu admin -->
            this.menusVisible.system = this.__checkRoles(['sadmin', 'admin']);
            this.menusVisible.admin = this.__checkRole('admin');
        },
    },
    computed: {},
    filters: {},
    watch: {
        '$root.mx_isLogin': {
            handler: function (value, oldValue) {
                this.setMenusVisible();
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
