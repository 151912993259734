<template>
    <div>
        <cookie v-if="!$root.mx_isLogin"></cookie>
        <router-view></router-view>
    </div>
</template>

<script>
import Cookie from "@/components/general/elements/notifications/Cookie";

export default {
    name: "AppContent",
    components: {Cookie},
    props: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>
.content {
    min-height: calc(100vh - 185px);
}
</style>
