import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {

  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

// <-- number and currency format -->
const numberFormats = {
  // <-- Europe EUR -->
  'sl-SI': {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
    },
    decimal_2: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    // percent: {
    //   style: 'percent',
    //   useGrouping: false
    // }
  },
  // <-- USA USD -->
  'en-US': {
    currency: {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    },
    decimal_2: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    // percent: {
    //   style: 'percent',
    //   useGrouping: false
    // }
  },
  // <-- British GBP -->
  'en-GB': {
    currency: {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2
    },
    decimal_2: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    // percent: {
    //   style: 'percent',
    //   useGrouping: false
    // }
  },
  // <-- Czech Koruna -->
  'cs-CZ': {
    currency: {
      style: 'currency',
      currency: 'CZK',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2
    },
    decimal_2: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    },
    // percent: {
    //   style: 'percent',
    //   useGrouping: false
    // }
  }
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'sl_SI',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en_GB',
  messages: loadLocaleMessages(),
  numberFormats
});
