import Vue from 'vue'

Vue.mixin({
    data() {
        return {}
    },
    methods: {
        /* Legend
        *
        * p         - price (wsp, rp)
        * pt         - price (wspt, rpt)
        *
        * wsp       - wholeSalePrice (nabavna cena)
        * wspt      - wholeSalePrice + tax (nabavna cena + DDV)
        *
        * tax       - tax in currency
        * tax_rate  - tax in percent
        *
        * cp        - commission in percent
        * cc        - commission in currency
        *
        * rp        - retail price (maloprodajna cena)
        * rpt       - retail price + tax (maloprodajna cena + DDV)
        *
        * ppi       - price per item
        */

        // <-- basic price, tax -->
        __calc_pt(p, tax_rate) {
            if (this.__isNull(p)) {
                return 0;
            }
            // <--  -->
            let pt = p + (p * tax_rate / 100);
            pt = this.__round(pt);
            return pt;
        },
        __calc_p(pt, tax_rate) {
            if (this.__isNull(pt)) {
                return 0;
            }
            // if (tax === 0) {
            //     return pt;
            // }
            // <--  -->
            let p = pt * 100 / (100 + tax_rate);
            p = this.__round(p);
            return p;
        },
        /*        __calc_tax(pt, p) {
                    if (this.__isNull(pt) || this.__isNull(p)) {
                        return null;
                    }
                    // <--  -->
                    let tax = pt - p;
                    return tax;
                },*/
        __calc_ppi(quantity, rpt) {
            if (this.__isNull(quantity) || this.__isNull(rpt)) {
                return 0;
            }
            // <--  -->
            let ppi = rpt / quantity;
            ppi = this.__round(ppi);
            return ppi;
        },
        // <-- wholeSalesPrice -->
        __calc_wsp_cp(rp, cp) {
            let wsp = this.__calc_p(rp, cp);
            // <--  -->
            return wsp;
        },
        __calc_wsp_cc(rp, cc) {
            if (this.__isNull(rp) || this.__isNull(cc)) {
                return 0;
            }
            // <--  -->
            let wsp = rp - cc;
            // <--  -->
            return wsp;
        },
        __calc_wspx_cp(rp, cp, tax_rate) {
            if (this.__isNull(rp) || this.__isNull(cp)) {
                return {
                    wsp: 0,
                    wspt: 0,
                };
            }
            // <-- calculating  -->
            let wspt = rp * 100 / (100 + cp);
            wspt = this.__round(wspt);
            // <-- calculating wsp -->
            let wsp = wspt * 100 / (100 + tax_rate);
            wsp = this.__round(wsp);
            // <--  -->
            return {
                wsp: wsp,
                wspt: wspt,
            };
        },
        __calc_wspx_cc(rp, cc, tax_rate) {
            // <-- calculating -->
            let wspt = rp - cc;
            wspt = this.__round(wspt);
            // <-- calculating wsp -->
            let wsp = wspt * 100 / (100 + tax_rate);
            wsp = this.__round(wsp);
            // <--  -->
            return {
                wsp: wsp,
                wspt: wspt,
            };
        },
        // <-- commission -->
        __calc_c_wsp_rp(wsp, rp) {
            let cc = rp - wsp;
            let cp = this.__calc_cp(rp, cc);
            // <--  -->
            return {
                cp: this.__round(cp),
                cc: this.__round(cc)
            }
        },

        /*
            supplierTaxpayer: true
            tenantTaxpayer: false
         */
        __calc_c_wsp_rp_tf(wspt, rp) {
            let cc = rp - wspt;
            let cp = this.__calc_cp(rp, cc);
            // <--  -->
            return {
                cp: this.__round(cp),
                cc: this.__round(cc)
            }
        },
        __calc_cc(wsp, cp) {
            if (this.__isNull(wsp) || this.__isNull(cp)) {
                return 0;
            }
            // <--  -->
            let cc = wsp * cp / 100;
            cc = this.__round(cc);
            return cc;
        },
        __calc_cp(p, cc) {
            if (this.__isNull(p) && this.__isNull(cc)) {
                return 0;
            }
            if (p - cc === 0) {
                return 0;
            }
            // <--  -->
            let cp = cc * 100 / (p - cc);
            cp = this.__round(cp);
            return cp;
        },
        // <-- retailPrice -->
        __calc_rp_cp(wsp, cp) {
            let cc = this.__calc_cc(wsp, cp);
            let rp = wsp + cc;
            rp = this.__round(rp);
            return rp;
        },
        __calc_rp_cc(wsp, cc) {
            // <--  -->
            let rp = wsp + cc;
            rp = this.__round(rp);
            return rp;
        },
        /*__calc_rpt(rp, pt) {
            // if(this.__isNull(price) || this.__isNull(commCurrency)){
            //     return 0;
            // }
            // <--  -->
            let rpt = rp + pt;
            return rpt;
        },*/
    },
    computed: {},
});
