<template>
    <div class="min-vh-100 p-3">

        <b-row class="p-3">
            <!-- las prlekija -->
            <b-col class="m-2">
                <a href="http://www.las-prlekija.com" target="_blank">
                    <img src="../../assets/images/logos/las Prlekija.jpg" height="90" width="auto"/>
                </a>
            </b-col>
            <!-- mgrt -->
            <b-col class="m-2">
                <a href="https://e-uprava.gov.si/si/drzava-in-druzba/javni-sektor/podrobnosti-institucije.html?id=12518"
                   target="_blank">
                    <img src="../../assets/images/logos/mgrt.jpg" height="90" width="auto"/>
                </a>
            </b-col>
            <!-- esrr -->
            <b-col class="m-2">
                <a href="https://www.europarl.europa.eu/factsheets/sl/sheet/95/europeiska-regionala-utvecklingsfonden-eruf-" target="_blank">
                    <img src="../../assets/images/logos/esrr.jpg" height="90" width="auto"/>
                </a>
            </b-col>
            <!-- smrk -->
            <b-col class="m-2"><img src="../../assets/images/logos/smrk.png" height="90" width="auto"/>
            </b-col>
        </b-row>

        <b-row no-gutters class="mt-3">
            <b-col class="">
                <a href="#/suppliers/null" style="text-decoration: none">
                    <b-card
                        bg-variant="secondary"
                        text-variant="primary"
                    >
                        <b-media>
                            <template #aside>
                                <b-img src="../../assets/images/prlekija/suppliers.jpg" width="80%"/>
                            </template>

                            <h1 class="mt-0">{{$t('menu.providers')}}</h1>
                            <p>
                                {{$t('farms_prlekija')}}
                            </p>
                        </b-media>
                    </b-card>
                </a>
            </b-col>
            <b-col class="">
                <a href="#/market/null" style="text-decoration: none">
                    <b-card
                        bg-variant="info"
                        text-variant="secondary"
                    >
                        <b-media>
                            <template #aside>
                                <b-img src="../../assets/images/prlekija/products.jpg" width="80%"/>
                            </template>

                            <h1 class="mt-0">{{$t('menu.market')}}</h1>
                            <p>
                                {{$t('simply_excellent')}}
                            </p>
                        </b-media>
                    </b-card>
                </a>
            </b-col>
            <!--            <b-col class="">
                            <a href="#/_prlekija" style="text-decoration: none">
                                <b-card
                                    bg-variant="primary"
                                    text-variant="secondary"
                                >
                                    <b-media>
                                        <template #aside>
                                            <b-img src="../../assets/images/prlekija/prlekija.jpg" width="80%"/>
                                        </template>

                                        <h1 class="mt-0">{{$t('menu.prlekija')}}</h1>
                                        <p>
                                            Lepote goric
                                        </p>
                                    </b-media>
                                </b-card>
                            </a>
                        </b-col>-->
            <b-col class="">
                <a href="#/_adventures2" style="text-decoration: none">
                    <b-card
                        bg-variant="primary"
                        text-variant="secondary"
                    >
                        <b-media>
                            <template #aside>
                                <b-img src="../../assets/images/pd/map.jpg" width="70%"/>
                            </template>

                            <h1 class="mt-0">{{$tc('adventure.experience.main', 1)}}</h1>
                            <p>
                                {{$t('around_prlekija')}}
                            </p>
                        </b-media>
                    </b-card>
                </a>
            </b-col>
        </b-row>
        <p></p>
        <img src="../../assets/images/prlekija/grapes.jpg" height="550px" width="100%" style="object-fit: cover"/>

    </div>
</template>

<script>
export default {
    name: "LandingPage",
    components: {},
    props: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
