import Vue from 'vue';
import utilsGeneral from "@/utils/utilsGeneral";

// <-- capitalize first letter -->
Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
});

// <-- upper all -->
Vue.filter('upper', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.toLocaleUpperCase()
});

// <-- lower all -->
Vue.filter('lower', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.toLocaleLowerCase()
});

// <-- data units -->
Vue.filter('bytesToSize', function (bytes, decimals = 2) {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
});

Vue.filter('bytesToSizeLocale', function (bytes, decimals = 2) {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return utilsGeneral.locale(parseFloat((bytes / Math.pow(k, i)).toFixed(dm))) + ' ' + sizes[i];
});

// <-- date, time -->
Vue.filter('baseDateToLocaleDate', function (baseDate, vueLocale) {
    let date = new Date(baseDate);
    return date.toLocaleDateString(vueLocale);
});
