<template>
    <div>
        <b-modal
            :id="'modalFormConfirmEmail' + componentKey"
            :cancel-title="$t('form.cancel')"
            :ok-title="$t('auth.send')"
            :title="$t('auth.confirmation_mail')"
            footerBgVariant="light"
            headerBgVariant="light"
            scrollable
            size="md"
            @cancel=""
            @hide="modalClose"
            @ok="send"
            @show="modalShow"
        >
            <!-- message -->
            <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
                <div class="text-secondary" v-html="$t('auth.confirmation_mail_message', {email: record.email})"></div>
                <!--                <hr>-->
                <!-- form -->
                <!--            <b-form ref="form">-->
                <!--                &lt;!&ndash; login email or username &ndash;&gt;-->
                <!--                <b-form-group-->
                <!--                    :label="$t('auth.email_username')"-->
                <!--                    label-for="email"-->
                <!--                >-->
                <!--                    <b-form-input-->
                <!--                        id="email"-->
                <!--                        v-model="record.emailUsername"-->
                <!--                        :placeholder="$t('auth.email_username_placeholder')"-->
                <!--                        required-->
                <!--                        :state="validEmailUsername"-->
                <!--                        size="sm"-->
                <!--                    ></b-form-input>-->
                <!--                    <b-form-invalid-feedback :state="validEmailUsername">-->
                <!--                        <div v-html="$t('form.required_field')"/>-->
                <!--                    </b-form-invalid-feedback>-->
                <!--                </b-form-group>-->
                <!--            </b-form>-->
            </b-overlay>
            <!-- alert warning -->
            <b-alert
                :show="errors.message != null"
                class="mb-0"
                dismissible
                variant="danger"
                @dismissed="resetErrors"
            >
                <div v-html="formatError"></div>
            </b-alert>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "ModalFormConfirmEmail",
    components: {},
    props: {
        componentKey: {
            type: String,
            default: ''
        },
        uri: null,
        emailUsername: null,
    },
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            form: {
                valid: true,
            },
            record: {
                email: null,
            },
            errors: {
                message: null,
            },
        }
    },
    mounted() {
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        resetErrors() {
            this.errors.message = null;
        },
        // <-- modal -->
        modalShow() {
            this.getEmail();
        },
        modalClose() {
            // this.showOverlay(false);
        },
        hideModal() {
            // <-- hide the modal manually -->
            this.$nextTick(() => {
                this.$bvModal.hide('modalFormConfirmEmail' + this.componentKey)
            });
        },
        // <-- form -->
        getEmail() {
            this.showOverlay(true);
            // <-- axios -->
            this.axios.post(this.uri.main + '/get_email',
                {
                    email: this.emailUsername
                })
                .then((response) => {
                    this.$set(this.record, 'email', response.data);
                    this.showOverlay(false);
                })
                .catch((error) => {
                    this.showOverlay(false);
                    if (error.response) {
                        console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                    }
                });
        },
        send(bvModalEvent) {
            this.errors.message = null;
            bvModalEvent.preventDefault();

            // <-- axios -->
            this.showOverlay(true);

            let href = location.protocol + '//' + location.host + '/#/';
            // <-- axios -->
            this.axios.post(this.uri.main + '/send_confirmation_email',
                {
                    'locale': this.$i18n.locale,
                    'href': href,
                    'email': this.record.email.toLowerCase(),
                })
                .then((response) => {
                    this.hideModal();
                    this.showOverlay(false);

                })
                .catch((error) => {
                    this.showOverlay(false);
                    // if(error.response === undefined){
                    //     return;
                    // }
                    error = error.response.data;
                    this.errors.message = error;
                })
                .finally(() => {
                });
        },
    },
    computed: {
        formatError() {
            let message = '';
            let error = this.errors.message;
            if (error === null) {
                return null;
            }
            // <-- error.message -->
            if (error.message !== undefined) {
                message = error.message;
            }
            // <-- error.errors -->
            if (error.errors !== undefined && typeof error.errors === 'object') {
                for (let key in error.errors) {
                    message += '<br><b>' + key + ':</b> ' + error.errors[key][0];
                }
            }
            // <--  -->
            return message;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
