<template>
    <b-nav-item-dropdown
        id="dropdown-form"
        :ref="'dropdown' + componentKey"
        right
        size="sm"
        variant="primary"
        @show="showDropDown"
    >
        <!-- avatar -->
        <template v-slot:button-content>
            <b-avatar
                :icon="$root.mx_isLogin ? 'person-fill' : 'person'"
                :src="__getAvatarSrc()"
                :variant="$root.mx_isLogin ? 'secondary' : 'secondary'"
                size="2em"
                style="padding: 2px"
            ></b-avatar>
        </template>
        <!-- content-->
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <!-- title -->
            <b-dropdown-text>
                <h4 class="text-secondary mb-0">Pozdravljeni!</h4>
            </b-dropdown-text>
            <b-dropdown-divider></b-dropdown-divider>
            <!-- login -->
            <b-dropdown-form v-if="!$root.mx_isLogin">
                <!-- login email or username -->
                <b-form-group
                    :label="$t('auth.email_username')"
                    label-for="email"
                >
                    <b-form-input
                        :id="`${parentComponentName}_email`"
                        v-model="record.email"
                        :placeholder="$t('auth.email_username_placeholder')"
                        required
                        size="sm"
                        @keydown="resendConfirmationMailVisible = false;"
                    ></b-form-input>
                </b-form-group>
                <!-- login password -->
                <b-form-group
                    id="group-password"
                    :label="$t('auth.password')"
                    label-for="password">
                    <b-form-input
                        :id="`${parentComponentName}_password`"
                        v-model="record.password"
                        :placeholder="$tc('auth.password_warning', 2)"
                        required
                        size="sm"
                        type="password"
                        autocomplete="false"
                        @keypress.enter="login()"
                    ></b-form-input>
                </b-form-group>
                <!-- login remember me -->
                <b-form-checkbox
                    v-model="record.remember_me"
                    class="mb-3"
                >
                    {{$t('auth.remember_me')}}
                </b-form-checkbox>
                <!-- login button -->
                <b-button
                    :id="`${parentComponentName}_button-login`"
                    block
                    size="sm"
                    variant="primary"
                    @click="login()"
                >
                    {{$t('auth.login')}}
                </b-button>
                <!-- error message -->
                <b-form-group
                    v-show="errors.loginMessage !== null"
                    :label="$t('auth.login_failed')"
                    class="text-danger p-0 mb-0 mx-0"
                    label-align="right"
                    label-class="m-0"
                    label-cols-sm="9"
                    :label-for="`${parentComponentName}_button-login`"
                    label-size="sm"
                >
                    <b-button
                        v-b-toggle.collapse-error-keyword
                        class="mx- mt-1 py-0 float-right"
                        size="sm"
                        variant="danger"
                    >?
                    </b-button>
                </b-form-group>

                <!-- collapse: login error -->
                <b-collapse id="collapse-error-keyword">
                    <b-card class="bg-danger text-light">
                        <div v-html="formatLoginError"></div>
                    </b-card>
                </b-collapse>

                <!-- resend mail for confirmation -->
                <b-dropdown-item
                    v-show="resendConfirmationMailVisible"
                    class="mt-2"
                    link-class="p-0"
                    variant="warning"
                    @click="resendConfirmationMail()"
                >
                    <b-dropdown-text>
                        {{$t('auth.confirmation_mail')}}
                    </b-dropdown-text>
                </b-dropdown-item>
                <!-- divider -->
                <b-dropdown-divider></b-dropdown-divider>
                <!-- forgotten password -->
                <b-dropdown-item
                    class="mt-2 font-weight-bold"
                    link-class="p-0"
                    variant="secondary"
                    @click="forgottenPassword()"
                >
                    {{$tc('auth.forgotten_password', 1)}}
                </b-dropdown-item>
                <!-- divider -->
                <b-dropdown-divider></b-dropdown-divider>
                <!-- registration -->
                <b-dropdown-item
                    link-class="p-0"
                    variant="secondary"
                    @click="registration()"
                >
                    {{$t('auth.are_you_new')}} <b>{{$t('auth.register')}}</b>
                </b-dropdown-item>
            </b-dropdown-form>
            <!-- logout -->
            <b-dropdown-form v-else>
                <!-- username or email -->
                <b-dropdown-text
                    v-if="!__isNull(user.username)"
                    class="text-secondary font-weight-bolder"
                    v-text="user.username"
                ></b-dropdown-text>
                <b-dropdown-text
                    class="text-secondary font-weight-bolder"
                    v-text="user.email"
                ></b-dropdown-text>
                <!-- role -->
                <b-dropdown-divider/>
                <b-dropdown-text
                    class="text-primary"
                    v-text="user.role"
                ></b-dropdown-text>
                <!-- profile -->
                <b-dropdown-divider/>
                <b-dropdown-item
                    disabled
                    link-class="p-0"
                >
                    {{$t('auth.profile')}}
                </b-dropdown-item>

                <b-dropdown-divider/>

                <!-- button logout -->
                <b-button
                    block
                    size="sm"
                    variant="primary"
                    @click="logout()"
                >
                    {{$t('auth.logout')}}
                </b-button>
            </b-dropdown-form>
            <!-- modal registration -->
            <modal-form-registration
                :component-key="componentKey"
                :uri="uri"
            ></modal-form-registration>
            <!-- modal forgotten password -->
            <ModalFormForgottenPassword
                :component-key="componentKey"
                :uri="uri"
            ></ModalFormForgottenPassword>
            <!-- modal resend confirmation mail -->
            <ModalFormConfirmEmail
                :component-key="componentKey"
                :email-username="record.email"
                :uri="uri"
            ></ModalFormConfirmEmail>
        </b-overlay>
    </b-nav-item-dropdown>
</template>

<script>
import ModalFormRegistration from "@/components/authentication/user/ModalFormRegistration";
import ModalFormForgottenPassword from "@/components/authentication/user/ModalFormForgottenPassword";
import ModalFormConfirmEmail from "@/components/authentication/user/ModalFormConfirmEmail";

export default {
    name: "DropdownUser",
    components: {ModalFormConfirmEmail, ModalFormForgottenPassword, ModalFormRegistration},
    props: {
        /**
         * @vuese
         * Component name; $options.name
         */
        parentComponentName: {
            type: String,
            default: null,
            required: true
        },
    },
    data() {
        return {
            componentKey: null,
            overlay: {
                visible: false,
                opacity: 0.7,
            },
            uri: {
                main: `api/v1/auth`,
            },
            resendConfirmationMailVisible: false,
            cryptRememberMe: {
                key: 'rememberMe',
                secretKey: 'g89jj8hbbmfsqgdMgObyajkh8jbdjinc',
            },
            record: {
                email: null,
                password: null,
                remember_me: false,
            },
            errors: {
                loginMessage: null,
            },
            user: {
                username: null,
                email: null,
            },
        }
    },
    mounted() {
        this.componentKey = this.__randomKey();
        this.$root.mx_isLogin = this.__isLogin();
    },
    methods: {
        showOverlay(show) {
            this.overlay.visible = show;
        },
        // <-- dropdown -->
        showDropDown() {
            this.$root.mx_isLogin = this.__isLogin();
            this.resendConfirmationMailVisible = false;

            if (this.$root.mx_isLogin) {
                // this.getUser();
                let authCookie = this.__getCookieValue();
                this.$set(this.user, 'email', authCookie.email);
                this.$set(this.user, 'username', authCookie.username);
                this.$set(this.user, 'role', this.__getRolesString());
                return;
            }
            this.restoreRememberMe();
        },
        hideDropDown() {
            this.$refs['dropdown' + this.componentKey].hide(true);
        },
        // <-- modal -->
        registration() {
            this.$bvModal.show('modalFormRegistration' + this.componentKey);
        },
        forgottenPassword() {
            this.$bvModal.show('modalFormForgottenPassword' + this.componentKey);
        },
        resendConfirmationMail() {
            this.$bvModal.show('modalFormConfirmEmail' + this.componentKey);
        },
        // <-- cookies -->
        setAuthCookie: function (data) {
            // <-- name -->
            let cookieName = this.CryptoJS.MD5('auth').toString();
            // <-- value -->
            let avatar_src = this.__isNull(data.user) || this.__isNull(data.user.picture) ? null : data.user.picture[0].src;
            // <--  -->
            let cookieValue = {
                access_token: data.access_token,
                token_type: data.token_type,
                username: data.user.username,
                email: data.user.email,
                avatar_src: avatar_src,
                user_right: data.user_right,
            };
            cookieValue = this.CryptoJS.AES.encrypt(JSON.stringify(cookieValue), this.auth__secretKey, null).toString();
            // <-- set cookie -->
            this.$cookies.set(cookieName, cookieValue, 0);
        },
        removeAuthCookie() {
            let cookieName = this.CryptoJS.MD5('auth').toString();
            $cookies.remove(cookieName);
        },
        // <-- local storage -->
        saveRememberMe() {
            let key = this.CryptoJS.MD5(this.cryptRememberMe.key);
            let value = {
                username: this.record.email,
                password: this.record.remember_me ? this.record.password : null,
                remember_me: this.record.remember_me
            };
            // <-- crypt -->
            value = JSON.stringify(value);
            value = this.CryptoJS.AES.encrypt(value, this.cryptRememberMe.secretKey, null).toString();
            // <--  -->
            localStorage.setItem(key, value);
        },
        restoreRememberMe() {
            let key = this.CryptoJS.MD5(this.cryptRememberMe.key);
            let value = localStorage.getItem(key);
            // <-- if value ==== null -->
            if (value === null) {
                let record = {
                    email: null,
                    password: null,
                    remember_me: false
                }
                this.$set(this, 'record', record);
                return;
            }
            // <-- decrypt -->
            value = this.CryptoJS.AES.decrypt(value, this.cryptRememberMe.secretKey, null).toString(this.CryptoJS.enc.Utf8);
            value = JSON.parse(value);
            // <--  -->
            let record = {
                email: value.username,
                password: value.remember_me ? value.password : null,
                remember_me: value.remember_me
            }
            this.$set(this, 'record', record);
        },
        // <-- form -->
        login() {
            this.errors.loginMessage = null;
            this.resendConfirmationMailVisible = false;
            // <-- remember me -->
            this.saveRememberMe();
            // <-- axios -->
            this.showOverlay(true);
            this.axios.post(this.uri.main + '/login',
                {
                    'locale': this.$i18n.locale,
                    'email': this.record.email,
                    'password': this.record.password,
                })
                .then((response) => {
                    this.setAuthCookie(response.data);
                    this.$root.mx_isLogin = true;
                    this.errors.loginMessage = null;
                    // <-- cartCount -->
                    this.$root.mx_cartCount = response.data.cartCount;
                    // <--  -->
                    this.showOverlay(false);
                    this.hideDropDown();
                })
                .catch((error) => {
                    // <-- account is not verified -->
                    if (error.response.status === 403) {
                        this.resendConfirmationMailVisible = true;
                    }
                    // <-- account is locked -->
                    if (error.response.status === 423) {
                        // in progress
                    }
                    // <-- error message -->
                    error = error.response.data;
                    this.$root.mx_isLogin = false;
                    this.errors.loginMessage = error;
                    this.showOverlay(false);
                })
                .finally(() => {
                });
        },
        logout() {
            this.showOverlay(true);
            // let authCookie = this.auth__getCookieValue();

            this.axios.get(this.uri.main + '/logout',
                {
                    // headers: {
                    //     "Content-Type": "application/json",
                    //     // 'Authorization': 'Bearer ' + authCookie.access_token
                    //     'Authorization': authCookie.token_type + ' ' + authCookie.access_token
                    // }
                    headers: this.__getHeaders()
                })
                .then((response) => {
                    this.removeAuthCookie();
                    this.$root.mx_isLogin = false;
                    // axios.defaults.headers.common['Authorization'] = null;
                    // <-- cartCount -->
                    this.$root.mx_cartCount = 0;
                    // <--  -->
                    this.showOverlay(false);
                    this.hideDropDown();
                    // <-- go to landing page -->
                    if (!this.isValidRoute()) {
                        this.$router.push('/');
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.removeAuthCookie();
                        this.$root.mx_isLogin = false;
                        this.showOverlay(false);
                        this.hideDropDown();
                        // <-- go to landing page -->
                        if (!this.isValidRoute()) {
                            this.$router.push('/');
                        }

                        // console.log(error.response.data);
                        // console.log(error.response.status);
                        // console.log(error.response.headers);
                    }
                });
        },
        // getUser() {
        //     this.showOverlay(true);
        //     // <-- axios -->
        //     this.axios.get(this.uri.main + '/get_user',
        //         {
        //             headers: this.__getHeaders()
        //         })
        //         .then((response) => {
        //             let authCookie = this.__getCookieValue();
        //             this.$set(this.user, 'email', authCookie.email);
        //             this.$set(this.user, 'username', authCookie.username);
        //             this.$set(this.user, 'role', this.__getRolesStampsString());
        //             this.showOverlay(false);
        //         })
        //         .catch((error) => {
        //             this.showOverlay(false);
        //             if (error.response) {
        //                 console.log(error.response.data);
        //                 // console.log(error.response.status);
        //                 // console.log(error.response.headers);
        //             }
        //         });
        // },
        isValidRoute() {
            let validRoutes = ['/', '/_prlekija', '/suppliers', '/market'];
            // <--  -->
            let path = this.$router.currentRoute.path;
            // console.log("path:", path);
            let array = path.split('/');
            let route = '/' + array[1];
            // <--  -->
            return validRoutes.includes(route);
        }
    },
    computed: {
        formatLoginError() {
            let message = '';

            let error = this.errors.loginMessage;
            if (error === null) {
                return null;
            }
            // <-- error.message -->
            if (error.message !== undefined) {
                message = error.message;
            }
            // <-- error.errors -->
            if (error.errors !== undefined && typeof error.errors === 'object') {
                message += '<br>';
                for (let key in error.errors) {
                    message += '<br>' + key + ': ' + error.errors[key][0];
                }
            }
            // <--  -->
            return message;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>
/*.b-dropdown-text {*/
/*    !*font-size: 11pt;*!*/
/*    !*padding-left: 0;*!*/
/*    !*color: gray;*!*/
/*}*/
</style>
