import Vue from 'vue'

Vue.mixin({
    data() {
        return {
            mx_pagination: {
                visible: false,
                perPage: 10,
                options: [
                    {value: 10, text: '10'},
                    {value: 15, text: '15'},
                    {value: 20, text: '20'},
                    {value: 50, text: '50'},
                    {value: 100, text: '100'},
                    {value: 200, text: '200'},
                ],
            },
            mx_busy: false,
        }
    },
    methods: {
        __isNull(value) {
            if (value === null || value === undefined) {
                return true;
            }
            // <-- value -->
            if (typeof value === 'number') {
                if (value === 0) {
                    return true;
                }
                return false;
            }
            // <-- string -->
            if (typeof value === 'string') {
                value = value.trim();
                if (value.length === 0) {
                    return true;
                }
                return false;
            }
            // <-- object -->
            if (typeof value === 'object') {
                if (value === undefined || Object.keys(value).length === 0) {
                    return true;
                }
                return false;
            }
            // <--  -->
            return false;
        },
        __isArray(value) {
            return Array.isArray(value);
        },
        __createJsonKey: (value) => {
            value = value.trim();
            value = value.replace(/ +/g, " ");  // replace multiple space with single space
            value = value.replace(/ /g, "_");
            value = value.toLowerCase();
            return value;
        },
        __createUniqueKey(index) {
            return this.CryptoJS.MD5(index + '_' + Date.now().toString()).toString();
        },
        __createStamp(basis) {
            if (this.__isNull(basis) || this.record.id !== 0) {
                return null;
            }
            // <-- create stamp -->
            let stamp = basis.toLowerCase();
            stamp = stamp.replace(/ +(?= )/g, '');
            stamp = stamp.split(' ').join('_');
            // <--  -->
            return stamp;
        },
        __capitalize: (value) => {
            if (typeof value !== 'string') {
                return ''
            }
            value = value.toLowerCase();
            return value.charAt(0).toUpperCase() + value.slice(1);
        },
        __clone: (value) => {
            return JSON.parse(JSON.stringify(value));
        },
        __formatErrorMessage(error) {
            let message = '';
            // <--  -->
            if (error === null) {
                return null;
            }
            // <-- error.message -->
            if (error.message !== undefined) {
                message = error.message;
            }
            // <-- error.errors -->
            if (error.errors !== undefined && typeof error.errors === 'object') {
                for (let key in error.errors) {
                    message += '<br><b>' + key + ':</b> ' + error.errors[key][0];
                }
            }
            // <--  -->
            return message;
        },
        __onlyIntegerKey: function (event) {
            let charCode = (event.which) ? event.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                event.preventDefault();
            } else {
                return true;
            }
        },
        __onlyDecimalKey: function (event) {
            let charCode = (event.which) ? event.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 44) {
                event.preventDefault();
            } else {
                return true;
            }
        },
        __onlyDecimalNegKey: function (event) {
            let charCode = (event.which) ? event.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 44 && charCode !== 45) {
                event.preventDefault();
            } else {
                return true;
            }
        },
        // <-- clipboard -->
        __copyToClipboard(value) {
            // https://stackoverflow.com/questions/54386279/how-can-i-use-navigator-clipboard-readtext-in-a-chrome-extension
            if (this.__isNull(value)) {
                return null;
            }
            // <--  -->
            switch (typeof value) {
                case 'string':
                    navigator.clipboard.writeText(value)
                        .then()
                    break;
            }
            // console.log("copy to clipboard", navigator.clipboard.readText().then((text) => {
            //     console.log('clipboard contents', text)
            // }));
        },
        /**
         * Generate random MD5 key
         * Normally is using for identification of components
         * @returns {number}
         * @private
         */
        __randomKey() {
            let key = Math.random().toString();
            key += Date.now().toString();
            key = this.CryptoJS.MD5(key).toString();
            // <--  -->
            return '_' + key;
        },
        // <-- number -->
        __round(number) {
            if (number === null) {
                number = 0;
            } else if (typeof number === 'string') {
                number = parseFloat(number);
            }
            // <--  -->
            return parseFloat(number.toFixed(this.__getCalculationDecimalNumber))
        },
        /**
         * Return formatted decimal number xx,xx
         * @param value
         * @returns {string}
         * @private
         */
        __formatDecimalNumber(value) {
            return parseFloat(value).toFixed(2).toLocaleString();
        },
        // <-- string -->
        __removeParagrafFromText(text) {
            if (typeof text !== 'string') {
                return text;
            }
            text = text.trim();
            if (text.substr(0, 3) === '<p>') {
                text = text.substr(3, text.length - 7);
            }
            return text;
        },
        // <-- object, array -->
        __getJsonLocaleValue(locale, item) {
            let string = null;
            if (locale[this.$i18n.locale] !== undefined && locale[this.$i18n.locale][item] !== undefined) {
                if (locale[this.$i18n.locale][item] !== null && locale[this.$i18n.locale][item].length > 0) {
                    string = locale[this.$i18n.locale][item];
                }
            }
            return string;
        },
        __getPaginationArrayItems(items, perPage, currentPage) {
            if (this.__isNull(items)) {
                return [];
            }
            // <--  -->
            let array = [];
            // <--  -->
            let min = (currentPage - 1) * perPage;
            let max = min + perPage;
            max = max > items.length ? items.length : max;

            // <--  -->
            for (let i = min; i < max; i++) {
                array.push(items[i]);
            }
            // <--  -->
            return array;
        },
        __getPaginationVisibleCount(allCount, perPage, currentPage) {
            let pages = allCount / perPage;
            pages = pages % 1 > 0 ? Math.floor(pages) + 1 : pages;
            let visible = perPage;
            // <--  -->
            if (perPage >= allCount) {
                visible = allCount
            }
            // <-- last page -->
            else if (currentPage === pages) {
                visible = (pages * perPage) - allCount;
            }
            // <--  -->
            return this.$t('form.showing') + ': ' + visible + '/' + allCount + '';
        },
        __convertObjetToArray(value) {
            if (this.__isArray(value)) {
                return value;
            }
            // <--  -->
            return Object.keys(value).map(function (key) {
                return value[key];
            });
        },
        __uniqueArray(value) {
            if (!this.__isArray(value)) {
                return value;
            }
            // <--  -->
            return [...new Set(value)];
        },
        __sortArray(value) {
            if (!this.__isArray(value)) {
                return value;
            }
            // <--  -->
            return value.sort((nameA, nameB) => nameA.localeCompare(nameB, 'si', {
                caseFirst: 'upper'  // lower
            }));
        },
        // <-- json data -->
        /**
         * Merging source object to target object in deep.
         * Be carefully with array. Use JSON.stringify(<object>); to fix it. It should be fixed:
         * @param {{}} source
         * @param {object|T[]} target
         */
        __mergeObjectDeep(source, target) {
            Object.keys(source).forEach(key => {
                // <-- item === object && !== null -->
                if (source[key] !== null && typeof source[key] === 'object' && !this.__isArray(source[key])) {
                    if ((target !== null && !target.hasOwnProperty(key)) || target[key] === null) {
                        this.$set(target, key, {});
                    }
                    this.__mergeObjectDeep(source[key], target[key]);
                }
                // <-- other items || item === null -->
                if (typeof source[key] !== 'object' || source[key] === null || this.__isArray(source[key])) {
                    let value = source[key];
                    if (target[key] === null || target[key] === undefined || !target.hasOwnProperty(key)) {
                        this.$set(target, key, value);
                    }
                }
            });
        },
        // <-- locale -->
        __getVueLocale() {
            return this.$i18n.locale.replace('_', '-');
        },
        __getLocaleI18n(locale) {
            // <-- check -->
            if (locale === null || locale[this.$i18n.locale] === undefined) {
                return null;
            }
            return locale[this.$i18n.locale];
        },
        __convertBaseDateToLocaleDateString(baseDate) {
            if (baseDate === null || baseDate === undefined || baseDate.length === 0) {
                return null;
            }
            let date = new Date(baseDate);
            let localeDate = date.toLocaleDateString(this.__getVueLocale());
            return localeDate;
        },
        __convertBaseDateToLocaleDateString_options(baseDate, options) {
            // const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            if (baseDate === null || baseDate === undefined || baseDate.length === 0) {
                return null;
            }
            let date = new Date(baseDate);
            let localeDate = date.toLocaleDateString(this.__getVueLocale(), options);
            return localeDate;
        },
        __getDecimalLocale(number) {
            return this.$n(number, 'decimal_2', this.__getVueLocale())
        },
        __getCurrencyLocale(number) {
            return this.$n(number, 'currency', this.__getVueCurrencyLocale);
        },
        // <-- date, time -->
        /*
         * replace 0a:bc with a:bc
        */
        __trimmedShapeTime(time) {
            if (this.__isNull(time)) {
                return time;
            }
            // <--  -->
            return time.indexOf('0') === 0 ? time.substring(1) : time;
        },
        // <-- picture, image -->
        __getImageSrc(baseSrc) {
            if (baseSrc === null) {
                return null;
            }
            return process.env.VUE_APP_API_IMAGES_PND + '/' + baseSrc;

        },
        __getAvatarSrc() {
            let baseSrc = this.__getCookieValue() === null ? null : this.__getCookieValue().avatar_src;
            return baseSrc === null ? null : this.__getImageSrc(baseSrc);
        },
        __imageSizeOptimum(widthMax, heightMax, imageWidth, imageHeight) {
            if (imageWidth > imageHeight) {
                let factor = imageWidth / imageHeight;
                let width = widthMax;
                let height = width / factor;
                return {
                    width: width + 'px',
                    height: height + 'px'
                }
            }
            // <--  -->
            let factor = imageHeight / imageWidth;
            let height = heightMax;
            let width = height / factor;

            return {
                width: width + 'px',
                height: height + 'px',
            }
        }
    },
    computed: {
        __isProductionMode() {
            return this.node_env = process.env.NODE_ENV === 'production';
        },
        __config() {
            return {
                headers: this.__getHeaders(),
                params: {
                    'locale': this.$i18n.locale,
                }
            };
        },
        __getCalculationDecimalNumber() {
            return 4;
        },
        __getVueCurrencyLocale() {
            // depends on tenant  state. It's not done, yet.
            return 'sl-SI';
        },
        __getTenantCurrency() {
            // depends on tenant  state. It in not done, yet.
            return '€';
        },
        __getAgeAlcohol() {
            // depends on tenant  state. It in not done, yet.
            return 18;
        },
        __getDefaultImage() {
            return require('@/assets/images/default.jpg');
        }
    },
});

