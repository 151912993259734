import Vue from 'vue'

Vue.mixin({
    data() {
        return {
            // <-- locale -->
            mx_locale: {
                content: {}
            },
            // <-- cart -->
            mx_cartUri: {
                main: `api/v1/cart/carts`,
                uri1: `api/v1/cart`,
            },
            mx_cartCount: 0,
            // <-- pd: adventure2 -->
            mx_countBooked: 0,
        }
    },
    methods: {
        // <-- Author -->
        __getCreatorName(data) {
            if (data === null) {
                return null;
            }
            // <--  -->
            let username = data.username;
            let name = data.person.name.name;
            let surname = data.person.name.surname;

            let creatorName = !this.__isNull(name) ? name : '';
            creatorName += creatorName.length > 0 ? ' ' : '';
            creatorName += !this.__isNull(surname) ? surname : '';
            creatorName += creatorName.length > 0 ? ' ' : '';
            creatorName += `(${username})`;
            // <--  -->
            return creatorName;
        },
        // <-- tableValues -->
        __getTableValuesObjectDefault(items) {
            let keys = Object.keys((items));
            let object = {};
            // <-- keys -->
            let value_a, value_b, value_c;
            keys.find((key) => {
                value_a = null;
                value_b = null;
                value_c = null;
                items[key].forEach(contact => {
                    if (value_a === null && value_b === null && value_c === null && !contact.deprecated) {
                        value_a = contact.value_a;
                        value_b = contact.value_b;
                        value_c = contact.value_c;
                    }
                    if (contact.default && !contact.deprecated) {
                        value_a = contact.value_a;
                        value_b = contact.value_b;
                        value_c = contact.value_c;
                    }
                });
                object[key] = {
                    value_a: value_a,
                    value_b: value_b,
                    value_c: value_c
                }
            });
            // <--  -->
            return object;
        },
        __getTableToolbarColumns(columns) {
            // <-- check if exists -->
            if (columns === undefined) {
                return [];
            }
            // <--  -->
            let values = [];
            let push;
            columns.forEach(column => {
                switch (column.key) {
                    // case 'tax_rate':
                    //     push = this.isTenantTaxpayer;
                    //     break;
                    default:
                        push = true;
                        break;
                }
                // <--  -->
                if (push) {
                    values.push({
                        key: column.key,
                        label: column.label,
                        sortable: column.sortable,
                        show: column.show,
                        alwaysShow: column.alwaysShow === undefined ? false : column.alwaysShow,
                    });
                }
            });
            // <--  -->
            return values;
        },
        // <-- state -->
        __getStateObject(states, state_id) {
            let state = null;
            // <-- locale -->
            states.find(n => {
                if (n.id === state_id) {
                    if (n.locale[this.$i18n.locale] !== undefined) {
                        Object.keys(n.locale).find((key) => {
                            if (key === this.$i18n.locale) {
                                state = n.locale[this.$i18n.locale];
                            }
                        });
                    }
                    if (state === null) {
                        state = n.meta;
                    }
                }
            });
            // <--  -->
            return state;
        },
        __getStateName(states, state_id) {
            let state = null;
            // <-- locale -->
            states.find(n => {
                if (n.id === state_id) {
                    if (n.locale[this.$i18n.locale] !== undefined) {
                        Object.keys(n.locale).find((key) => {
                            if (key === this.$i18n.locale) {
                                state = n.locale[this.$i18n.locale];
                            }
                        });
                    }
                    if (state === null) {
                        state = n.meta;
                    }
                }
            });
            // <-- meta name -->
            let name = state.name.length > 0 ? state.name : null;
            if (name === null && state.full_name.length > 0) {
                name = state.full_name;
            }
            // <--  -->
            return name;
        },
        // <-- cart -->
        __cartCount: function () {
            if (!this.__isLogin()) {
                this.$root.mx_cartCount = 0;
                return;
            }
            // <-- api -->
            const config = {
                headers: this.__getFormDataHeaders(false)
            }
            // <-- axios -->
            this.axios.get(this.mx_cartUri.uri1 + `/count`, config)
                .then((response) => {
                    this.$root.mx_cartCount = response.data;
                })
                .catch((error) => {
                    // on error executed
                });
        }
    },
    computed: {
        /**
         * @vuese
         * locale content such as: firstDayOfWeek, age_alcohol...
         */
        __localeContent: {
            get() {
                return this.$root.mx_locale.content;
            },
            set(value) {
                this.$root.mx_locale.content = value;
            }
        }
    },
});
