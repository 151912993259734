import Vue from 'vue'

Vue.mixin({
    data() {
        return {
            topLayout: [],
        }
    },
    methods: {
        /**
         * Add height and weight to global-root variable topLayout[]
         * @param componentName
         * @param width
         * @param height
         * @private
         */
        __layoutTopAdd(componentName, width, height) {
            let topLayout = this.$root.topLayout;
            let exist = false;
            // <-- check if exists -->
            topLayout.every(component => {
                if (component.componentName === componentName) {
                    component.width = width;
                    component.height = height;
                    exist = true;
                    return false;
                }
                return true;
            });
            // <--  -->
            if (!exist) {
                this.$root.topLayout.push({
                    componentName: componentName,
                    width: width,
                    height: height,
                });
            }
        },
        /**
         * General method to add height and weight to local component variable
         * @param array
         * @param componentName
         * @param width
         * @param height
         * @returns {*}
         * @private
         */
        __layoutAdd(array, componentName, width, height) {
            let exist = false;
            // <-- check if exists -->
            array.every(component => {
                if (component.componentName === componentName) {
                    component.width = width;
                    component.height = height;
                    exist = true;
                    return false;
                }
                return true;
            });
            // <--  -->
            if (!exist) {
                array.push({
                    componentName: componentName,
                    width: width,
                    height: height,
                });
            }
            // <--  -->
            return array;
        },

        __layoutHeightCalculate(layout) {
            let sum = 0;
            layout.forEach(value => {
                sum += value.height;
            });
            return sum;
        }
    },
    computed: {
        __layoutTopHeightsGet() {
            return this.$root.topLayout;
        },
        __layoutCenterHeightCalculate() {
            let sum = 0;
            this.$root.topLayout.forEach(value => {
                sum += value.height;
            });
            let calc = window.innerHeight - sum - 15;
            return {
                value: calc,
                px: calc + 'px'
            };
        }
    },
});
