import 'core-js/stable'; // ex: import '@babel/polyfill';
import "regenerator-runtime/runtime";
import 'mutationobserver-shim';

import Vue from 'vue'
import App from './App.vue'

import './config/bootstrap-vue'
import i18n from './config/i18n'

import router from './config/vue-router/router';
import './config/axios'
import '@/config/vue2-editor/vue2-editor'
import './config/vue-js-grid'
import './config/vue-cryptojs';
import '@/config/vue-cookies';
import '@/config/leaflet/leaflet';
import './config/vue-treeselect'
import '@/config/splitpanes';
import '@/config/vue-element-resize-detector';
import '@/config/vuedraggable';

// <-- global -->
import '@/global/general';
import '@/global/layout';
import '@/global/auth';
import '@/global/data';
import '@/global/about';
import '@/global/finance';
import '@/global/let-s-go';
import '@/global/vue2Editor';

import './utils/Filters';

Vue.config.productionTip = false;

// <-- Main version -->
Vue.prototype.$mainVersion = '0.9.4 RD: 2023052901';
// <--  -->
import '../public/css/custom1.scss'
// import '../_public/css/custom2.scss'

// import '../_public/css/pnd-general.css'
// import '../_public/css/pnd-theme1.css'
// import '../_public/css/pnd-theme2.css'

new Vue({
    i18n,
    router,
    render: h => h(App)
}).$mount('#app')
