<template>
    <div v-resize @resize="layoutResize" class="sticky-top">
        <!--        <b-navbar class="d-block d-md-none p-0" type="light" variant="primary" :sticky="true">-->
        <b-navbar class="d-block d-lg-none p-0" type="light" variant="primary" :sticky="true">
            <b-navbar-nav>
                <!-- hamburger button -->
                <b-nav-item-dropdown
                    id="dropdown-form"
                    ref="dropdown"
                    no-caret
                    menu-class="bg-primary"
                    style="align-items: center; display: grid"
                    class="align-content-center"
                >
                    <template #button-content>
                        <b-button variant="secondary" size="sm" class="p-1" @click="submenuVisible=true;">
                            <b-icon-list></b-icon-list>
                        </b-button>
                    </template>
                    <b-dropdown-form v-if="">
                        <!-- submenu -->
                        <sub-menu-main-sm
                            @eventHide="submenuHide()"
                        ></sub-menu-main-sm>
                    </b-dropdown-form>
                </b-nav-item-dropdown>

                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <!-- search -->
                    <!--                                    <b-nav-form>    &lt;!&ndash; // TODO search &ndash;&gt;
                                                            <b-input-group size="sm">
                                                                <template #append>
                                                                    <b-button type="submit">
                                                                        <b-icon-search></b-icon-search>
                                                                    </b-button>
                                                                </template>
                                                                <b-form-input :placeholder="$t('search.placeholder')"></b-form-input>
                                                            </b-input-group>
                                                        </b-nav-form>-->
                    <!-- per page -->
                    <b-nav-form>
                        <b-form-select
                            v-show="$root.mx_pagination.visible"
                            v-model="$root.mx_pagination.perPage"
                            :options="mx_pagination.options"
                            class="mx-1 "
                            size="sm"
                            style="width: 65px"
                        ></b-form-select>
                        <!-- button toolbar -->
                        <b-button-toolbar>
                            <!-- cart -->
                            <CartButton class="ml-1"></CartButton>
                            <!-- locales -->
                            <b-dropdown
                                v-if="!$root.__isProductionMode"
                                class="d-flex justify-content-center align-items-center"
                                right variant="primary"
                            >
                                <!-- button icon -->
                                <template v-slot:button-content>
                                    <b-icon
                                        aria-hidden="false"
                                        icon="flag"
                                        variant="secondary"
                                    ></b-icon>
                                </template>
                                <!-- title -->
                                <b-dropdown-text class="h5 text-secondary font-weight-bold">
                                    <h4 class="text-secondary mb-0">{{$t('menu.language')}}</h4>
                                </b-dropdown-text>
                                <b-dropdown-divider></b-dropdown-divider>
                                <!-- content-->
                                <div v-for="locale in locales">
                                    <b-dropdown-item-button
                                        value="locale.locale"
                                        @click="setLocale(locale)"
                                        :active="$i18n.locale === locale.locale"
                                    >
                                        {{locale.language}}
                                    </b-dropdown-item-button>
                                </div>
                            </b-dropdown>
                            <!-- books -->
                            <b-dropdown
                                class="d-flex justify-content-center align-items-center"
                                right variant="primary"
                            >
                                <!-- button icon -->
                                <template v-slot:button-content>
                                    <b-icon
                                        aria-hidden="false"
                                        icon="book-half"
                                        variant="secondary"
                                    ></b-icon>
                                </template>
                                <b-dropdown-item href="books/prlekijanadlani.pdf" target="_blank">Prlekija na dlani (pdf)
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- menu admin -->
                            <b-dropdown
                                v-if="menusVisible.sistem"
                                class="d-flex justify-content-center align-items-center ml-1"
                                right
                                variant="primary"
                            >
                                <!-- button icon -->
                                <template v-slot:button-content>
                                    <b-icon
                                        aria-hidden="true"
                                        icon="tools"
                                        variant="secondary"
                                    ></b-icon>
                                </template>
                                <!-- themes -->
                                <!--<b-dropdown-text class="h5 text-secondary font-weight-bold">
                                    Teme
                                </b-dropdown-text>
                                <b-dropdown-divider></b-dropdown-divider>
                                &lt;!&ndash; content&ndash;&gt;
                                <b-dropdown-item-button>
                                    <b-icon-droplet scale="0.6"></b-icon-droplet>
                                    {{$t('theme.spring')}}
                                </b-dropdown-item-button>
                                <b-dropdown-item-button>
                                    <b-icon-brightness-high scale="0.6"></b-icon-brightness-high>
                                    {{$t('theme.summer')}}
                                </b-dropdown-item-button>
                                <b-dropdown-item-button>
                                    <b-icon-cloud scale="0.6"></b-icon-cloud>
                                    {{$t('theme.fall')}}
                                </b-dropdown-item-button>
                                <b-dropdown-item-button>
                                    <b-icon-asterisk scale="0.6"></b-icon-asterisk>
                                    {{$t('theme.winter')}}
                                </b-dropdown-item-button>
                                <b-dropdown-divider></b-dropdown-divider>-->

                                <!-- admin -->
                                <b-dropdown-text>
                                    <h4 class="text-secondary mb-0">Admin</h4>
                                </b-dropdown-text>
                                <b-dropdown-divider></b-dropdown-divider>
                                <!-- content-->
                                <!--                    <b-dropdown-item href="http://pillars:26868/api/documentation" target="_blank">Dokumentacija API</b-dropdown-item>-->
                                <b-dropdown-item to="/admin">{{$t('menu.admin')}}</b-dropdown-item>
                                <b-dropdown-item to="/trade">{{$t('menu.trade')}}</b-dropdown-item>
                                <b-dropdown-item to="/adventure">{{$t('menu.adventure.adventures')}}</b-dropdown-item>
                                <b-dropdown-item to="/business">{{$t('menu.business')}}</b-dropdown-item>
                                <b-dropdown-item to="/register">{{$t('menu.register')}}</b-dropdown-item>
                                <b-dropdown-divider></b-dropdown-divider>
                                <!-- developer -->
                                <b-dropdown-text>
                                    <h4 class="text-secondary mb-0">Developer</h4>
                                </b-dropdown-text>
                                <b-dropdown-divider></b-dropdown-divider>
                                <!-- sandbox-->
                                <b-dropdown-item to="/developer/sandboxs">{{$t('menu.sandbox_s')}}</b-dropdown-item>
                                <b-dropdown-item to="/developer/sandboxm">{{$t('menu.sandbox_m')}}</b-dropdown-item>
                                <b-dropdown-item to="/developer/community">{{$t('menu.community')}}</b-dropdown-item>
                                <b-dropdown-item to="/developer/theme_preview">{{$t('menu.theme_preview')}}</b-dropdown-item>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item to="/developer/vue_leaflet">Vue-leaflet</b-dropdown-item>
                                <b-dropdown-item to="/developer/leaflet_js">Leaflet js</b-dropdown-item>
                            </b-dropdown>
                        </b-button-toolbar>
                    </b-nav-form>
                    <!-- dropdownUser -->
                    <DropdownUser
                        :parent-component-name="$options.name"
                    ></DropdownUser>
                </b-navbar-nav>
                <!-- DEPRECATED -->
                <!-- Right aligned nav items -->
                <!--                <b-navbar-nav class="ml-auto">
                                    &lt;!&ndash; search &ndash;&gt;
                &lt;!&ndash;                    <b-nav-form>
                                        <b-input-group size="sm">
                                            <template #append>
                                                <b-button type="submit">
                                                    <b-icon-search></b-icon-search>
                                                </b-button>
                                            </template>
                                            <b-form-input></b-form-input>
                                        </b-input-group>
                                    </b-nav-form>&ndash;&gt;

                                    &lt;!&ndash; mini cart button &ndash;&gt;
                &lt;!&ndash;                    <b-nav-item>
                                        <b-button variant="secondary" size="sm" @click="showMiniCart()">
                                            <b-icon-basket></b-icon-basket>
                                            <b-badge class="cart-number ml-1 text-black-50" pill active variant="info">
                                                12
                                                <span class="sr-only">Izdelkov v košarici</span>
                                            </b-badge>
                                        </b-button>
                                    </b-nav-item>&ndash;&gt;

                                    &lt;!&ndash;  MINI CART  &ndash;&gt;
                &lt;!&ndash;                    <b-card v-if="showDiv"
                                            no-body
                                            body-bg-variant="secondary"
                                            class="mini-cart active"
                                    >

                                        &lt;!&ndash;  MINI CART header  &ndash;&gt;
                                        <b-card-header header-bg-variant="primary"
                                                       class="text-center"
                                                       header-text-variant="white"
                                        >
                                            <b-row align-h="between">
                                                <b-col cols="11" class="font-weight-bold">MINI KOŠARICA</b-col>
                                                <b-col cols="1">
                                                    <b-button-close class="close" text-variant="white" aria-label="close"
                                                                    @click="hideMiniCart()">
                                                        <span aria-hidden="true" class="font-weight-bold">&times;</span>
                                                    </b-button-close>
                                                </b-col>
                                                &lt;!&ndash;                    <b-col cols="2">2</b-col>&ndash;&gt;
                                            </b-row>

                                        </b-card-header>
                                        <b-card-body style="padding: 0">

                                            &lt;!&ndash;   MINI CART products  &ndash;&gt;
                                            <b-card no-body>
                                                <b-row no-gutters align-v="center">
                                                    <b-col cols="2"><img src="@/assets/images/zelenjava.jpeg" height="auto"
                                                                         width="60" alt="zelenjava"/></b-col>
                                                    <b-col cols="4">Rjavi šampinjoni
                                                    </b-col>
                                                    <b-col cols="1">1 x</b-col>
                                                    <b-col cols="2" class="text-center">0,5 kg</b-col>
                                                    <b-col cols="2" class="text-right">2,28 €</b-col>
                                                    <b-col cols="1">
                                                        <b-button-close class="close" text-variant="black" aria-label="close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </b-button-close>
                                                    </b-col>
                                                </b-row>
                                            </b-card>
                                        </b-card-body>

                                        &lt;!&ndash;   MINI CART footer  &ndash;&gt;
                                        <b-card-footer footer-bg-variant="primary"
                                                       footer-text-variant="white"
                                                       style="padding: 0"
                                        >
                                            <b-row style="padding: 10px" align-h="between">
                                                <b-col cols="6"><b>SKUPAJ:</b></b-col>
                                                <b-col cols="6" class="text-right"><b>2,28 €</b></b-col>
                                            </b-row>
                                            <b-row no-gutters>
                                                <b-col cols="6"><b>
                                                    <b-button block squared>V KOŠARICO</b-button>
                                                </b></b-col>
                                                <b-col cols="6"><b>
                                                    <b-button variant="danger" block squared>NA BLAGAJNO</b-button>
                                                </b></b-col>
                                            </b-row>
                                        </b-card-footer>
                                    </b-card>&ndash;&gt;
                                </b-navbar-nav>-->
            </b-navbar-nav>
        </b-navbar>
    </div>
</template>

<script>
import SubMenuMainSm from "@/components/general/layout/menu/main/SubMenuMainSm";
import CartButton from "@/components/_public/cart/CartButton";
import DropdownUser from "@/components/authentication/user/DropdownUser";

export default {
    name: "MenuMainSm",
    components: {DropdownUser, CartButton, SubMenuMainSm},
    props: {},
    data() {
        return {
            // showDiv: false,
            uri: {
                main: `api/v1/_public`,
            },
            locales: {},
            menusVisible: {
                manager: false,
                sistem: false,
                admin: false,
            }
        }
    },
    mounted() {
        this.loadLocales();
        this.getLocale();
    },
    methods: {
        layoutResize(e) {
            this.__layoutTopAdd(this.$options.name, e.detail.width, e.detail.height);
        },
        // <--  -->
        submenuHide() {
            this.$refs.dropdown.hide(true);
        },
        // setActive() {
        //     let routeManager = this.$router.currentRoute.path;
        //     let paths = routeManager.split('/');
        //     routeManager = paths[1];
        //
        //     if (routeManager === '') { // <-- home -->
        //         this.getMenuItems.forEach(item => {
        //             item.active = item.path === routeManager + '/';
        //         });
        //         return;
        //     }
        //     this.getMenuItems.forEach(item => {
        //         item.active = item.path.includes(routeManager);
        //     });
        // },
        setMenusVisible() {
            // <-- menu manager -->
            this.menusVisible.manager = this.__checkPermission('sale', ['read', 'write']);
            // <-- menu admin -->
            this.menusVisible.sistem = this.__checkRoles(['sadmin', 'admin']);
            this.menusVisible.admin = this.__checkRole('admin');
        },
        // <-- locale -->
        loadLocales() {
            this.axios.get(this.uri.main + `/getLocales`)
                .then((response) => {
                    this.locales = response.data;
                })
                .catch((error) => {
                    // console.log("error:", error);
                })
                .finally(function () {
                    // always executed
                });
        },
        getLocale() {
            // console.log("navigator:", navigator.language.split('-')[0] || process.env.VUE_APP_I18N_LOCALE || 'en');
            if (localStorage.locale == null) {
                return;
            }
            this.$set(this.$i18n, 'locale', localStorage.locale);
        },
        setLocale(locale) {
            this.$set(this.$i18n, 'locale', locale.locale);
            localStorage.locale = locale.locale;
            location.reload();
        },
    },
    computed: {
        language: {
            get() {
                for (let index in this.locales) {
                    if (this.locales[index].locale === this.$i18n.locale) {
                        return this.locales[index].language;
                    }
                }
                return this.$t('menu.language');
            }
        },
    },
    filters: {},
    watch: {
        '$root.mx_isLogin': {
            handler: function (value, oldValue) {
                this.setMenusVisible();
            },
            deep: true
        },
    }
}
</script>

<style scoped>

</style>
