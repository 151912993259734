import Vue from 'vue'

Vue.mixin({
    data() {
        return {
            auth__secretKey: 'ajkh8jbmfshbbygduubbdjiqg89jj8nc',
            tenant__secretKey: 'ljk48jdmfshub89jj8nbdjbbygduiqgc',
            mx_isLogin: false,
        }
    },
    methods: {
        // <-- auth -->
        __isLogin() {
            let cookieName = this.CryptoJS.MD5('auth');
            return $cookies.isKey(cookieName);
        },
        __getCookieValue() {
            let cookieName = this.CryptoJS.MD5('auth');

            // <-- return null if no cookie -->
            if (!$cookies.isKey(cookieName)) {
                return null;
            }
            // <--  -->
            let cookieValue = this.CryptoJS.AES.decrypt(this.$cookies.get(cookieName), this.auth__secretKey, null).toString(this.CryptoJS.enc.Utf8);
            return JSON.parse(cookieValue);
        },
        __getHeaders: function () {
            let authCookie = this.__getCookieValue();

            let token_type = authCookie !== null ? authCookie.token_type : 'Bearer';
            let access_token = authCookie !== null ? authCookie.access_token : null;

            // <-- headers -->
            return {
                'content-type': 'application/json',
                // 'Authorization': 'Bearer ' + authCookie.access_token
                // 'Authorization': authCookie.token_type + ' ' + authCookie.access_token
                'authorization': token_type + ' ' + access_token,
                'locales': this.$i18n.locale,
            };
        },
        __getFormDataHeaders: function (close) {
            let authCookie = this.__getCookieValue();

            let token_type = authCookie !== null ? authCookie.token_type : 'Bearer';
            let access_token = authCookie !== null ? authCookie.access_token : null;

            // <-- headers -->
            return {
                'Content-Type': 'multipart/form-data',
                // 'Authorization': 'Bearer ' + authCookie.access_token
                // 'Authorization': authCookie.token_type + ' ' + authCookie.access_token
                'Authorization': token_type + ' ' + access_token,
                'locales': this.$i18n.locale,
                'close': close,
            };
        },
        // <-- tenant -->
        __getTenantCookieValue() {
            let cookieName = this.CryptoJS.MD5('tenant');

            // <-- return null if no cookie -->
            if (!$cookies.isKey(cookieName)) {
                return null;
            }
            // <--  -->
            let cookieValue = this.CryptoJS.AES.decrypt(this.$cookies.get(cookieName), this.tenant__secretKey, null).toString(this.CryptoJS.enc.Utf8);
            return JSON.parse(cookieValue);
        },
        // <-- roles, permissions -->
        __getRolesStampsString() {
            // <-- cookie values -->
            let cookieValue = this.__getCookieValue();
            if (cookieValue === null) {
                return false;
            }
            // <--  -->
            let string = cookieValue.user_right.roles.stamps.join(', ');

            string = string.length > 0 ? string : this.$tc('auth.user', 1).toLowerCase();
            return string;
        },
        __getRolesString() {
            // <-- cookie values -->
            let cookieValue = this.__getCookieValue();
            if (cookieValue === null) {
                return false;
            }
            // <--  -->
            let roles_full = cookieValue.user_right.roles.full;
            let roles = [];
            roles_full.forEach(role => {
                role = role[0];
                roles.push(!this.__isNull(role.locale_name) ? role.locale_name : role.meta_name);
            });
            // <-- string -->
            let string = roles.join(', ');
            string = string.length > 0 ? string : this.$tc('auth.user', 1).toLowerCase();
            return string;
        },
        __checkRole(stamp) {
            // <-- cookie values -->
            let cookieValue = this.__getCookieValue();
            if (cookieValue === null) {
                return false;
            }
            // <--  -->
            return cookieValue.user_right.roles.stamps.includes(stamp);
        },
        __checkRoles(stamps) {
            // <-- cookie values -->
            let cookieValue = this.__getCookieValue();
            if (cookieValue === null) {
                return false;
            }
            // <-- stamps -->
            let validity = false;
            stamps.every(stamp => {
                if (cookieValue.user_right.roles.stamps.includes(stamp)) {
                    validity = true;
                    return false;
                }
                return true;
            });
            // <--  -->
            return validity;
        },
        __checkPermission(stamp, permissions) {
            // <-- cookie values -->
            let cookieValue = this.__getCookieValue();
            if (cookieValue === null) {
                return false;
            }
            // <-- rights, permission -->
            let validity = false;
            cookieValue.user_right.rights.find(n => {
                if (n.accesses.stamp === stamp) {
                    // <-- permission -->
                    permissions.every(permission => {
                        validity = n.right[permission];
                        if (!validity) {
                            return;
                        }
                        return true;
                    });
                    return;
                }
            });
            // <--  -->
            return validity;
        },
    },
    computed: {},
});

