export default {
    clone: (source) => {
        return JSON.parse(JSON.stringify(source));
    },

    isNull: (source) => {
        return (source != null || source !== undefined);
    },

    keyExists(object, key) {
        return Object.keys(object).includes(key);
    },
    // <-- deprecated: moved in global.general -->
    capitalize: (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    },

    locale: (number) => {
        let locale = localStorage.locale === undefined ? process.env.VUE_APP_I18N_LOCALE : localStorage.locale;
        locale = locale.replace('_', '-');
        return number.toLocaleString(locale);
    },
}
