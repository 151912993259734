import Vue from 'vue';

import {
    LMap,
    LTileLayer,
    LPopup,
    LMarker,
    LTooltip,
    LControlLayers,
    LControlZoom,
    LControlAttribution,
    LControlScale,
    LLayerGroup,
    LPolyline,
    LPolygon,
    LRectangle,
    LCircle,
} from 'vue2-leaflet';
// <-- css -->
import 'leaflet/dist/leaflet.css';

// <-- context menu -->
import 'leaflet-contextmenu';
import '@/config/leaflet/css/contextMenu.css';

// <-- gesture handling -->
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

// <-- routing machine -->
import 'leaflet-routing-machine/dist/leaflet-routing-machine';
import '@/config/leaflet/css/routing.css';

// <-- geocoder -->
import '@/config/leaflet/Control.Geocoder';
import '@/config/leaflet/css/Control.Geocoder.css';

// <-- components -->
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-popup', LPopup);
Vue.component('l-marker', LMarker);
Vue.component('l-tooltip', LTooltip);
Vue.component('l-control-layers', LControlLayers);
Vue.component('l-control-zoom', LControlZoom);
Vue.component('l-control-attribution', LControlAttribution);
Vue.component('l-control-scale', LControlScale);
Vue.component('l-layer-group', LLayerGroup);
Vue.component('l-polyline', LPolyline);
Vue.component('l-polygon', LPolygon);
Vue.component('l-rectangle', LRectangle);
Vue.component('l-circle', LCircle);

// <-- icons -->
import {Icon} from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
