<template>
    <div id="app">
        <div id="wrapper-www">
            <b-container fluid>
                <app-header></app-header>
                <menu-main></menu-main>
                <menu-main-sm></menu-main-sm>

                <app-content-error v-if="!isValidTenant"></app-content-error>
                <app-content v-else></app-content>

                <app-footer></app-footer>
            </b-container>
        </div>
    </div>
</template>

<script>
import AppHeader from "@/components/general/layout/AppHeader";
import MenuMain from "@/components/general/layout/menu/main/MenuMain";
import MenuMainSm from "@/components/general/layout/menu/main/MenuMainSm";
import AppContent from "@/components/general/layout/AppContent";
import AppFooter from "@/components/general/layout/AppFooter";
import AppContentError from "@/components/general/layout/AppContentError";

export default {
    name: "master-layout",
    components: {
        AppContentError,
        AppHeader,
        MenuMain,
        MenuMainSm,
        AppContent,
        AppFooter
    },
    data() {
        return {
            isValidTenant: true,
        }
    },
    mounted() {
        this.getTenant();
    },
    methods: {
        getTenant() {
            // <-- api -->
            this.axios.put(`api/v1/tenant/getTenantBasic`,
                {
                    'origin': location.origin,
                })
                .then((response) => {
                    if (!response.data.id > 0) {
                        this.isValidTenant = false;
                        return;
                    }
                    // <--  -->
                    this.isValidTenant = true;
                    this.setTenantCookie(response.data);
                })
                .catch((error) => {
                    this.isValidTenant = false;
                });
        },
        // <-- cookies -->
        setTenantCookie: function (data) {
            // <-- name -->
            let cookieName = this.CryptoJS.MD5('tenant').toString();
            // <-- value -->
            let cookieValue = {
                id: data.id,
                organisation_id: data.organisation_id,
                taxpayer: data.organisation.taxpayer,
                currency: data.currency.content,
                origin: data.origin,
                state_id: data.state_id,
                timezone: data.timetone,
                unit: data.unit
            };
            cookieValue = this.CryptoJS.AES.encrypt(JSON.stringify(cookieValue), this.tenant__secretKey, null).toString();
            // <-- set cookie -->
            this.$cookies.set(cookieName, cookieValue, 0);
        },
    },

};
</script>

<style lang="scss">
// Import custom SASS variable overrides, or alternatively
// define your variable overrides here instead
// Import Bootstrap and BootstrapVue source SCSS files

@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';
@import '../public/css/pnd-general.css';
@import '../public/css/pnd-theme1.css';
</style>
