<template>
    <div>
        <footer class="bg-dark text-success pt-3 pb-0">
            <b-container fluid class="text-light text-center">
                <b-row>
                    <b-col>
                        <br>
                        <!--                        <b-link to="/howitworks">Kako deluje?</b-link>-->
                        <!--                        <br>-->
                        <b-link to="/about">O projektu</b-link>
                        <br>
                        <b-link to="/contacts">Kontakti</b-link>
                        <br>
                        <!-- facebook link -->
                        <template>
                            <a href="https://www.facebook.com/PrlekijaNaDlani" target="_blank">
                                <div class="h2 mb-3">
                                    <b-icon icon="facebook" variant="">

                                    </b-icon>
                                </div>
                            </a>
                        </template>
                        <!-- legal -->
                        <b-link to="/spp">Splošni pogoji poslovanja</b-link>
                        |
                        <b-link to="/cookies">Piškotki</b-link>
                        |
                        <b-link to="/privacy">Zasebnost</b-link>
                        <br>
                        ©SMRK 2021. Vse pravice pridržane.<br>
                        <br>
                    </b-col>
                </b-row>
                <b-row class="p-3 bg-white">
                    <!-- las prlekija -->
                    <b-col class="m-2">
                        <a href="http://www.las-prlekija.com" target="_blank">
                            <img src="../../../assets/images/logos/las Prlekija.jpg" height="90" width="auto"/>
                        </a>
                    </b-col>
                    <!-- mgrt -->
                    <b-col class="m-2">
                        <a href="https://e-uprava.gov.si/si/drzava-in-druzba/javni-sektor/podrobnosti-institucije.html?id=12518"
                           target="_blank">
                            <img src="../../../assets/images/logos/mgrt.jpg" height="90" width="auto"/>
                        </a>
                    </b-col>
                    <!-- esrr -->
                    <b-col class="m-2">
                        <a href="https://www.europarl.europa.eu/factsheets/sl/sheet/95/europeiska-regionala-utvecklingsfonden-eruf-"
                           target="_blank">
                            <img src="../../../assets/images/logos/esrr.jpg" height="90" width="auto"/>
                        </a>
                    </b-col>
                    <b-col class="m-2"><img src="../../../assets/images/logos/smrk.png" height="90" width="auto"/>
                    </b-col>
                </b-row>
            </b-container>
        </footer>
    </div>
</template>

<script>
export default {
    name: "AppFooter",
    components: {},
    props: {},
    data() {
        return {
            showTop: true
        }
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
