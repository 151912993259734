<template>
    <b-alert
        v-model="showTop"
        class="position-fixed fixed-bottom m-0 rounded-0"
        style="z-index: 2000;"
        variant="primary"
        dismissible
    >
        <span v-html="$t('notification.cookie', {uri_cookies: '#/cookies'})"></span>
    </b-alert>
</template>

<script>
export default {
    name: "Cookie",
    components: {},
    props: {},
    data() {
        return {
            showTop: true
        }
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
