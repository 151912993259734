// require
// var Vue = require('vue')
// Vue.use(require('vue-cookies'))

// es2015 module
import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// set default config
Vue.$cookies.config('1y')

// set global cookie
// Vue.$cookies.set('theme','default');
// Vue.$cookies.set('hover-time','1s');
